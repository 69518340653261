@keyframes rotation {
  from {
    transform: rotate(359deg);
  }

  to {
    transform: rotate(0);
  }
}

@keyframes shake {
  0% {
    transform: translateX(0);
  }

  10%, 30%, 50%, 70%, 90% {
    transform: translateX(-5px);
  }

  20%, 40%, 60%, 80% {
    transform: translateX(5px);
  }

  100% {
    transform: translateX(0);
  }
}

@keyframes tw-spin {
  to {
    transform: rotate(360deg);
  }
}

@font-face {
  font-family: Inter;
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url("Inter-Thin.229ba378.woff2") format("woff2");
}

@font-face {
  font-family: Inter;
  font-style: italic;
  font-weight: 100;
  font-display: swap;
  src: url("Inter-ThinItalic.9f81ab2f.woff2") format("woff2");
}

@font-face {
  font-family: Inter;
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url("Inter-ExtraLight.a7c091c3.woff2") format("woff2");
}

@font-face {
  font-family: Inter;
  font-style: italic;
  font-weight: 200;
  font-display: swap;
  src: url("Inter-ExtraLightItalic.715e9b66.woff2") format("woff2");
}

@font-face {
  font-family: Inter;
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url("Inter-Light.d257580c.woff2") format("woff2");
}

@font-face {
  font-family: Inter;
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url("Inter-LightItalic.fc7aee7e.woff2") format("woff2");
}

@font-face {
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("Inter-Regular.c3474174.woff2") format("woff2");
}

@font-face {
  font-family: Inter;
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url("Inter-Italic.f23ba37f.woff2") format("woff2");
}

@font-face {
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url("Inter-Medium.275a3474.woff2") format("woff2");
}

@font-face {
  font-family: Inter;
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url("Inter-MediumItalic.a2ebbdce.woff2") format("woff2");
}

@font-face {
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url("Inter-SemiBold.0cbc243f.woff2") format("woff2");
}

@font-face {
  font-family: Inter;
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url("Inter-SemiBoldItalic.eedb80b8.woff2") format("woff2");
}

@font-face {
  font-family: Inter;
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("Inter-Bold.ff33b6b2.woff2") format("woff2");
}

@font-face {
  font-family: Inter;
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url("Inter-BoldItalic.67e04f74.woff2") format("woff2");
}

@font-face {
  font-family: Inter;
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url("Inter-ExtraBold.7783db4d.woff2") format("woff2");
}

@font-face {
  font-family: Inter;
  font-style: italic;
  font-weight: 800;
  font-display: swap;
  src: url("Inter-ExtraBoldItalic.449f9604.woff2") format("woff2");
}

@font-face {
  font-family: Inter;
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url("Inter-Black.f108228e.woff2") format("woff2");
}

@font-face {
  font-family: Inter;
  font-style: italic;
  font-weight: 900;
  font-display: swap;
  src: url("Inter-BlackItalic.be778dd7.woff2") format("woff2");
}

.dsp-bg-glow:not(#\#):not(#\#) {
  background: linear-gradient(0deg, #e7efff 0%, #fff 15% 85%, #e7efff 100%);
}

.dsp-shake-calendar-slot:not(#\#):not(#\#) {
  animation: .5s shake;
}

.dsp-flyer:not(#\#):not(#\#) {
  z-index: 100;
  transition-property: top, left, width, height;
  transition-duration: .8s;
  transition-timing-function: ease-in-out;
  position: absolute;
  -webkit-mask-image: radial-gradient(circle, #000 40%, #0000 80%);
  mask-image: radial-gradient(circle, #000 40%, #0000 80%);
}

:root:not(#\#):not(#\#) {
  --dsp-range-input-track-height: .5rem;
  --dsp-range-input-thumb-height: 1rem;
}

input.dsp-range-input[type="range"]:not(#\#):not(#\#) {
  -webkit-appearance: none;
  appearance: none;
  cursor: pointer;
  background: none;
  width: 100%;
}

input.dsp-range-input[type="range"]:focus:not(#\#):not(#\#) {
  outline: none;
}

input.dsp-range-input[type="range"]:not(#\#):not(#\#)::-webkit-slider-runnable-track {
  background-color: var(--color-ink-brand-subtle);
  border-radius: .5rem;
  height: .5rem;
}

input.dsp-range-input[type="range"]:not(#\#):not(#\#)::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  background-color: var(--color-ink-brand-default);
  height: var(--dsp-range-input-thumb-height);
  width: var(--dsp-range-input-thumb-height);
  border-radius: .5rem;
  margin-top: -.25rem;
}

input.dsp-range-input[type="range"]:not(#\#):not(#\#)::-moz-range-track {
  background-color: var(--color-ink-brand-subtle);
  border-radius: .5rem;
  height: .5rem;
}

input.dsp-range-input[type="range"]:not(#\#):not(#\#)::-moz-range-thumb {
  background-color: var(--color-ink-brand-default);
  height: var(--dsp-range-input-thumb-height);
  width: var(--dsp-range-input-thumb-height);
  border-radius: calc(--dsp-range-input-thumb-height / 2);
  border: none;
}

.form-control:not(#\#):not(#\#):not(#\#):not(#\#), .form-check-input:not(#\#):not(#\#):not(#\#):not(#\#) {
  border-width: 1px !important;
}

body:not(#\#):not(#\#) {
  font-family: var(--default-font-family);
  color: var(--color-ink-brand-default);
  text-align: start;
  line-height: 1.5;
}

body:not(#\#):not(#\#) a:hover:not(#\#):not(#\#) {
  text-decoration: underline;
}

td:not(#\#):not(#\#), th:not(#\#):not(#\#) {
  text-align: inherit;
}

p:not(#\#):not(#\#) {
  margin-bottom: 1em;
}

h1:not(#\#):not(#\#), h2:not(#\#):not(#\#), h3:not(#\#):not(#\#), h4:not(#\#):not(#\#), h5:not(#\#):not(#\#), h6:not(#\#):not(#\#) {
  color: inherit;
  margin-top: 0;
  margin-bottom: .5rem;
  font-weight: 600;
  line-height: 1.2;
}

h1:not(#\#):not(#\#) {
  font-size: 1.5rem;
}

h2:not(#\#):not(#\#) {
  font-size: 1.25rem;
}

h3:not(#\#):not(#\#) {
  font-size: 1.125rem;
}

h4:not(#\#):not(#\#) {
  font-size: 1rem;
}

h5:not(#\#):not(#\#), h6:not(#\#):not(#\#) {
  font-size: .875rem;
}

svg:not(#\#):not(#\#) {
  width: 2rem;
}

:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#), :not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):before, :not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):after, :not(#\#):not(#\#):not(#\#):not(#\#):not(#\#)::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position: ;
  --tw-gradient-via-position: ;
  --tw-gradient-to-position: ;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
  --tw-contain-size: ;
  --tw-contain-layout: ;
  --tw-contain-paint: ;
  --tw-contain-style: ;
}

:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#), :not(#\#):not(#\#):not(#\#):not(#\#):not(#\#), :not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):before, :not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):after {
  box-sizing: border-box;
  border-style: solid;
  border-width: 0;
}

input[type="number"]:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#)::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"]:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#)::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"]:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  -webkit-appearance: textfield;
  appearance: textfield;
}

.tw-sr-only:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

.tw-pointer-events-none:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  pointer-events: none;
}

.tw-pointer-events-auto:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  pointer-events: auto;
}

.tw-invisible:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  visibility: hidden;
}

.tw-fixed:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  position: fixed;
}

.tw-absolute:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  position: absolute;
}

.tw-relative:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  position: relative;
}

.tw-inset-0:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  inset: 0;
}

.-tw-left-16:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  left: -4rem;
}

.-tw-right-16:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  right: -4rem;
}

.tw--right-2:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  right: -.5rem;
}

.tw-bottom-0:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  bottom: 0;
}

.tw-bottom-6:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  bottom: 1.5rem;
}

.tw-bottom-\[-6px\]:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  bottom: -6px;
}

.tw-bottom-full:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  bottom: 100%;
}

.tw-left-0:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  left: 0;
}

.tw-left-1\/2:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  left: 50%;
}

.tw-left-2:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  left: .5rem;
}

.tw-left-3:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  left: .75rem;
}

.tw-left-6:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  left: 1.5rem;
}

.tw-left-7:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  left: 1.75rem;
}

.tw-left-\[-12px\]:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  left: -12px;
}

.tw-right-0:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  right: 0;
}

.tw-right-2:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  right: .5rem;
}

.tw-right-7:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  right: 1.75rem;
}

.tw-right-8:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  right: 2rem;
}

.tw-right-\[-12px\]:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  right: -12px;
}

.tw-top-0:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  top: 0;
}

.tw-top-1\/2:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  top: 50%;
}

.tw-top-2:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  top: .5rem;
}

.tw-top-3:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  top: .75rem;
}

.tw-top-7:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  top: 1.75rem;
}

.tw-top-\[-6px\]:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  top: -6px;
}

.tw-z-0:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  z-index: 0;
}

.tw-z-10:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  z-index: 10;
}

.tw-z-20:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  z-index: 20;
}

.tw-z-50:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  z-index: 50;
}

.tw-col-span-full:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  grid-column: 1 / -1;
}

.tw-m-0:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  margin: 0;
}

.tw-mx-auto:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  margin-left: auto;
  margin-right: auto;
}

.-tw-mb-10:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  margin-bottom: -2.5rem;
}

.tw-mb-0:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  margin-bottom: 0;
}

.tw-mb-4:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  margin-bottom: 1rem;
}

.tw-ml-auto:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  margin-left: auto;
}

.tw-mr-2:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  margin-right: .5rem;
}

.tw-mr-auto:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  margin-right: auto;
}

.tw-mt-4:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  margin-top: 1rem;
}

.tw-line-clamp-2:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
}

.tw-block:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  display: block;
}

.tw-inline-block:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  display: inline-block;
}

.tw-flex:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  display: flex;
}

.tw-inline-flex:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  display: inline-flex;
}

.tw-table-cell:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  display: table-cell;
}

.tw-grid:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  display: grid;
}

.tw-hidden:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  display: none;
}

.tw-aspect-square:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  aspect-ratio: 1;
}

.tw-aspect-video:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  aspect-ratio: 16 / 9;
}

.tw-size-5:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  width: 1.25rem;
  height: 1.25rem;
}

.tw-size-6:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  width: 1.5rem;
  height: 1.5rem;
}

.tw-size-full:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  width: 100%;
  height: 100%;
}

.tw-h-1:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  height: .25rem;
}

.tw-h-24:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  height: 6rem;
}

.tw-h-3:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  height: .75rem;
}

.tw-h-4:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  height: 1rem;
}

.tw-h-5:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  height: 1.25rem;
}

.tw-h-6:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  height: 1.5rem;
}

.tw-h-8:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  height: 2rem;
}

.tw-h-\[120\%\]:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  height: 120%;
}

.tw-h-\[42px\]:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  height: 42px;
}

.tw-h-\[500px\]:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  height: 500px;
}

.tw-h-\[51px\]:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  height: 51px;
}

.tw-h-full:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  height: 100%;
}

.tw-h-screen:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  height: 100vh;
}

.tw-max-h-\[90vh\]:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  max-height: 90vh;
}

.tw-min-h-full:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  min-height: 100%;
}

.tw-w-1:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  width: .25rem;
}

.tw-w-10:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  width: 2.5rem;
}

.tw-w-12:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  width: 3rem;
}

.tw-w-14:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  width: 3.5rem;
}

.tw-w-24:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  width: 6rem;
}

.tw-w-3:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  width: .75rem;
}

.tw-w-32:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  width: 8rem;
}

.tw-w-4:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  width: 1rem;
}

.tw-w-44:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  width: 11rem;
}

.tw-w-48:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  width: 12rem;
}

.tw-w-5:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  width: 1.25rem;
}

.tw-w-6:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  width: 1.5rem;
}

.tw-w-7:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  width: 1.75rem;
}

.tw-w-auto:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  width: auto;
}

.tw-w-fit:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  width: fit-content;
}

.tw-w-full:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  width: 100%;
}

.tw-w-max:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  width: max-content;
}

.tw-w-screen:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  width: 100vw;
}

.tw-min-w-20:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  min-width: 5rem;
}

.tw-min-w-96:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  min-width: 24rem;
}

.tw-max-w-2xl:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  max-width: 42rem;
}

.tw-max-w-36:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  max-width: 9rem;
}

.tw-max-w-3xl:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  max-width: 48rem;
}

.tw-max-w-40:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  max-width: 10rem;
}

.tw-max-w-44:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  max-width: 11rem;
}

.tw-max-w-48:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  max-width: 12rem;
}

.tw-max-w-4xl:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  max-width: 56rem;
}

.tw-max-w-96:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  max-width: 24rem;
}

.tw-max-w-\[500px\]:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  max-width: 500px;
}

.tw-max-w-\[800px\]:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  max-width: 800px;
}

.tw-max-w-lg:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  max-width: 32rem;
}

.tw-max-w-max:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  max-width: max-content;
}

.tw-max-w-md:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  max-width: 28rem;
}

.tw-max-w-none:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  max-width: none;
}

.tw-max-w-sm:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  max-width: 24rem;
}

.tw-flex-1:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  flex: 1;
}

.tw-flex-shrink-0:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#), .tw-shrink-0:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  flex-shrink: 0;
}

.tw-flex-grow:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#), .tw-grow:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  flex-grow: 1;
}

.tw-table-fixed:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  table-layout: fixed;
}

.tw-border-collapse:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  border-collapse: collapse;
}

.tw-origin-top:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  transform-origin: top;
}

.-tw-translate-x-0\.5:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  --tw-translate-x: -.125rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-tw-translate-x-1\/2:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  --tw-translate-x: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-tw-translate-y-1\/2:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  --tw-translate-y: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-tw-translate-y-4:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  --tw-translate-y: -1rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.tw--translate-x-1\/2:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  --tw-translate-x: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.tw--translate-y-1\/2:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  --tw-translate-y: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.tw-translate-x-1\/2:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  --tw-translate-x: 50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.tw-translate-x-\[22px\]:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  --tw-translate-x: 22px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.tw-translate-y-0:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  --tw-translate-y: 0px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.tw-translate-y-2:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  --tw-translate-y: .5rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.tw-rotate-0:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  --tw-rotate: 0deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.tw-rotate-180:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  --tw-rotate: 180deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.tw-rotate-90:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  --tw-rotate: 90deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.tw-rotate-\[270deg\]:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  --tw-rotate: 270deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.tw-scale-100:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.tw-scale-95:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  --tw-scale-x: .95;
  --tw-scale-y: .95;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.tw-transform:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.tw-animate-spin:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  animation: 1s linear infinite tw-spin;
}

.\!tw-cursor-not-allowed:not(#\#) {
  cursor: not-allowed !important;
}

.tw-cursor-default:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  cursor: default;
}

.tw-cursor-not-allowed:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  cursor: not-allowed;
}

.tw-cursor-pointer:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  cursor: pointer;
}

.tw-select-none:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  -webkit-user-select: none;
  user-select: none;
}

.tw-resize-y:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  resize: vertical;
}

.tw-list-none:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  list-style-type: none;
}

.tw-appearance-none:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  -webkit-appearance: none;
  appearance: none;
}

.tw-grid-cols-12:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  grid-template-columns: repeat(12, minmax(0, 1fr));
}

.tw-grid-cols-\[max-content_auto_max-content\]:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  grid-template-columns: max-content auto max-content;
}

.tw-flex-row:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  flex-direction: row;
}

.tw-flex-row-reverse:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  flex-direction: row-reverse;
}

.tw-flex-col:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  flex-direction: column;
}

.tw-flex-col-reverse:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  flex-direction: column-reverse;
}

.tw-flex-wrap:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  flex-wrap: wrap;
}

.tw-flex-wrap-reverse:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  flex-wrap: wrap-reverse;
}

.tw-flex-nowrap:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  flex-wrap: nowrap;
}

.tw-items-start:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  align-items: flex-start;
}

.tw-items-end:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  align-items: flex-end;
}

.tw-items-center:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  align-items: center;
}

.tw-items-stretch:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  align-items: stretch;
}

.tw-justify-start:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  justify-content: flex-start;
}

.tw-justify-end:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  justify-content: flex-end;
}

.tw-justify-center:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  justify-content: center;
}

.tw-justify-between:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  justify-content: space-between;
}

.tw-justify-around:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  justify-content: space-around;
}

.tw-justify-evenly:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  justify-content: space-evenly;
}

.tw-justify-stretch:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  justify-content: stretch;
}

.tw-gap-0:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  gap: 0;
}

.tw-gap-1:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  gap: .25rem;
}

.tw-gap-10:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  gap: 2.5rem;
}

.tw-gap-12:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  gap: 3rem;
}

.tw-gap-16:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  gap: 4rem;
}

.tw-gap-2:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  gap: .5rem;
}

.tw-gap-20:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  gap: 5rem;
}

.tw-gap-24:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  gap: 6rem;
}

.tw-gap-3:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  gap: .75rem;
}

.tw-gap-32:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  gap: 8rem;
}

.tw-gap-4:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  gap: 1rem;
}

.tw-gap-40:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  gap: 10rem;
}

.tw-gap-48:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  gap: 12rem;
}

.tw-gap-5:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  gap: 1.25rem;
}

.tw-gap-56:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  gap: 14rem;
}

.tw-gap-6:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  gap: 1.5rem;
}

.tw-gap-64:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  gap: 16rem;
}

.tw-gap-8:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  gap: 2rem;
}

.tw-gap-x-0\.5:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  -moz-column-gap: .125rem;
  column-gap: .125rem;
}

.tw-gap-x-1:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  -moz-column-gap: .25rem;
  column-gap: .25rem;
}

.tw-gap-x-2:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  -moz-column-gap: .5rem;
  column-gap: .5rem;
}

.tw-gap-x-4:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  -moz-column-gap: 1rem;
  column-gap: 1rem;
}

.tw-gap-y-2:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  row-gap: .5rem;
}

.tw-gap-y-3:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  row-gap: .75rem;
}

.tw-space-y-4:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1rem * var(--tw-space-y-reverse));
}

.tw-space-y-8:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(2rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(2rem * var(--tw-space-y-reverse));
}

.tw-overflow-auto:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  overflow: auto;
}

.tw-overflow-hidden:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  overflow: hidden;
}

.tw-overflow-clip:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  overflow: clip;
}

.tw-overflow-visible:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  overflow: visible;
}

.tw-overflow-scroll:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  overflow: scroll;
}

.tw-overflow-x-auto:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  overflow-x: auto;
}

.tw-overflow-y-auto:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  overflow-y: auto;
}

.tw-overflow-x-hidden:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  overflow-x: hidden;
}

.tw-scroll-smooth:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  scroll-behavior: smooth;
}

.tw-whitespace-normal:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  white-space: normal;
}

.tw-whitespace-nowrap:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  white-space: nowrap;
}

.tw-whitespace-pre:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  white-space: pre;
}

.tw-text-pretty:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  text-wrap: pretty;
}

.tw-break-words:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  overflow-wrap: break-word;
}

.tw-rounded:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  border-radius: .25rem;
}

.tw-rounded-button:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  border-radius: var(--button-border-radius);
}

.tw-rounded-card-lg:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  border-radius: var(--card-border-radius-lg);
}

.tw-rounded-full:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  border-radius: 9999px;
}

.tw-rounded-lg:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  border-radius: .5rem;
}

.tw-rounded-md:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  border-radius: .375rem;
}

.tw-rounded-none:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  border-radius: 0;
}

.tw-rounded-b:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  border-bottom-right-radius: .25rem;
  border-bottom-left-radius: .25rem;
}

.tw-rounded-b-lg:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  border-bottom-right-radius: .5rem;
  border-bottom-left-radius: .5rem;
}

.tw-rounded-b-none:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.tw-rounded-l-none:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.tw-rounded-r-none:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.tw-rounded-t-lg:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  border-top-left-radius: .5rem;
  border-top-right-radius: .5rem;
}

.tw-rounded-t-sm:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  border-top-left-radius: .125rem;
  border-top-right-radius: .125rem;
}

.tw-rounded-bl-none:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  border-bottom-left-radius: 0;
}

.tw-rounded-br-none:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  border-bottom-right-radius: 0;
}

.tw-rounded-tl-none:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  border-top-left-radius: 0;
}

.tw-rounded-tr-none:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  border-top-right-radius: 0;
}

.tw-border:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  border-width: 1px;
}

.tw-border-0:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  border-width: 0;
}

.tw-border-2:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  border-width: 2px;
}

.tw-border-x:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  border-left-width: 1px;
  border-right-width: 1px;
}

.tw-border-x-0:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  border-left-width: 0;
  border-right-width: 0;
}

.tw-border-y-0:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  border-top-width: 0;
  border-bottom-width: 0;
}

.tw-border-b:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  border-bottom-width: 1px;
}

.tw-border-b-\[1\.5px\]:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  border-bottom-width: 1.5px;
}

.tw-border-l-2:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  border-left-width: 2px;
}

.tw-border-t:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  border-top-width: 1px;
}

.tw-border-t-0:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  border-top-width: 0;
}

.tw-border-solid:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  border-style: solid;
}

.tw-border-none:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  border-style: none;
}

.\!tw-border-error-600:not(#\#) {
  border-color: var(--color-error-600) !important;
}

.\!tw-border-ink-error:not(#\#) {
  border-color: var(--color-ink-error) !important;
}

.tw-border-base-300:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  border-color: var(--color-base-300);
}

.tw-border-base-400:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  border-color: var(--color-base-400);
}

.tw-border-base-transparent:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  border-color: var(--color-base-transparent);
}

.tw-border-button-danger-bg:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  border-color: var(--color-button-danger-bg);
}

.tw-border-button-primary-bg:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  border-color: var(--color-button-primary-bg);
}

.tw-border-button-secondary-border:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  border-color: var(--color-button-secondary-border);
}

.tw-border-ink-border-default:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  border-color: var(--color-ink-border-default);
}

.tw-border-ink-border-subtle:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  border-color: var(--color-ink-border-subtle);
}

.tw-border-ink-brand-default:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  border-color: var(--color-ink-brand-default);
}

.tw-border-ink-brand-subtle:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  border-color: var(--color-ink-brand-subtle);
}

.tw-border-ink-disabled:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  border-color: var(--color-ink-disabled);
}

.tw-border-surface-action:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  border-color: var(--color-surface-action);
}

.tw-border-surface-default:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  border-color: var(--color-surface-default);
}

.tw-border-transparent:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  border-color: #0000;
}

.tw-border-b-ink-border-subtle:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  border-bottom-color: var(--color-ink-border-subtle);
}

.tw-border-l-error-600:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  border-left-color: var(--color-error-600);
}

.tw-border-t-ink-brand-subtle:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  border-top-color: var(--color-ink-brand-subtle);
}

.\!tw-bg-base-50:not(#\#) {
  background-color: var(--color-base-50) !important;
}

.\!tw-bg-surface-error:not(#\#) {
  background-color: var(--color-surface-error) !important;
}

.tw-bg-base-0:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  background-color: var(--color-base-0);
}

.tw-bg-base-100:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  background-color: var(--color-base-100);
}

.tw-bg-base-300:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  background-color: var(--color-base-300);
}

.tw-bg-base-400:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  background-color: var(--color-base-400);
}

.tw-bg-base-50:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  background-color: var(--color-base-50);
}

.tw-bg-base-900:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  background-color: var(--color-base-900);
}

.tw-bg-base-transparent:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  background-color: var(--color-base-transparent);
}

.tw-bg-button-danger-bg:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  background-color: var(--color-button-danger-bg);
}

.tw-bg-button-disabled-bg:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  background-color: var(--color-button-disabled-bg);
}

.tw-bg-button-primary-bg:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  background-color: var(--color-button-primary-bg);
}

.tw-bg-button-secondary-bg:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  background-color: var(--color-button-secondary-bg);
}

.tw-bg-button-tertiary-bg:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  background-color: var(--color-button-tertiary-bg);
}

.tw-bg-chip-active:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  background-color: var(--color-chip-active);
}

.tw-bg-chip-default:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  background-color: var(--color-chip-default);
}

.tw-bg-error-100:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  background-color: var(--color-error-100);
}

.tw-bg-information-100:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  background-color: var(--color-information-100);
}

.tw-bg-ink-brand-subtle:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  background-color: var(--color-ink-brand-subtle);
}

.tw-bg-success-100:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  background-color: var(--color-success-100);
}

.tw-bg-surface-action:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  background-color: var(--color-surface-action);
}

.tw-bg-surface-default:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  background-color: var(--color-surface-default);
}

.tw-bg-surface-default-hover:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  background-color: var(--color-surface-default-hover);
}

.tw-bg-surface-default-inverted:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  background-color: var(--color-surface-default-inverted);
}

.tw-bg-surface-error:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  background-color: var(--color-surface-error);
}

.tw-bg-surface-information:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  background-color: var(--color-surface-information);
}

.tw-bg-surface-success:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  background-color: var(--color-surface-success);
}

.tw-bg-surface-tip:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  background-color: var(--color-surface-tip);
}

.tw-bg-surface-warning:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  background-color: var(--color-surface-warning);
}

.tw-bg-transparent:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  background-color: #0000;
}

.tw-bg-warning-100:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  background-color: var(--color-warning-100);
}

.tw-bg-cover:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  background-size: cover;
}

.tw-bg-center:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  background-position: center;
}

.\!tw-fill-ink-error:not(#\#) {
  fill: var(--color-ink-error) !important;
}

.tw-fill-button-primary-text:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  fill: var(--color-button-primary-text);
}

.tw-fill-button-secondary-text:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  fill: var(--color-button-secondary-text);
}

.tw-fill-error-600:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  fill: var(--color-error-600);
}

.tw-fill-information-600:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  fill: var(--color-information-600);
}

.tw-fill-ink-brand-default:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  fill: var(--color-ink-brand-default);
}

.tw-fill-ink-brand-inverted:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  fill: var(--color-ink-brand-inverted);
}

.tw-fill-ink-disabled:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  fill: var(--color-ink-disabled);
}

.tw-fill-link-default:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  fill: var(--color-link-default);
}

.tw-fill-link-inverted:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  fill: var(--color-link-default-inverted);
}

.tw-fill-success-600:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  fill: var(--color-success-600);
}

.tw-fill-surface-action:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  fill: var(--color-surface-action);
}

.tw-fill-surface-default:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  fill: var(--color-surface-default);
}

.tw-fill-warning-600:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  fill: var(--color-warning-600);
}

.tw-object-cover:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  -o-object-fit: cover;
  object-fit: cover;
}

.\!tw-p-1:not(#\#) {
  padding: .25rem !important;
}

.tw-p-0:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  padding: 0;
}

.tw-p-1:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  padding: .25rem;
}

.tw-p-12:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  padding: 3rem;
}

.tw-p-2:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  padding: .5rem;
}

.tw-p-3:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  padding: .75rem;
}

.tw-p-4:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  padding: 1rem;
}

.tw-p-6:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  padding: 1.5rem;
}

.tw-p-8:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  padding: 2rem;
}

.tw-px-1:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  padding-left: .25rem;
  padding-right: .25rem;
}

.tw-px-2:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  padding-left: .5rem;
  padding-right: .5rem;
}

.tw-px-3:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  padding-left: .75rem;
  padding-right: .75rem;
}

.tw-px-4:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  padding-left: 1rem;
  padding-right: 1rem;
}

.tw-px-6:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.tw-px-8:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  padding-left: 2rem;
  padding-right: 2rem;
}

.tw-px-button-compact-padding-x:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  padding-left: var(--button-compact-padding-x);
  padding-right: var(--button-compact-padding-x);
}

.tw-px-button-regular-padding-x:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  padding-left: var(--button-regular-padding-x);
  padding-right: var(--button-regular-padding-x);
}

.tw-py-0\.5:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  padding-top: .125rem;
  padding-bottom: .125rem;
}

.tw-py-1:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  padding-top: .25rem;
  padding-bottom: .25rem;
}

.tw-py-2:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.tw-py-3:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  padding-top: .75rem;
  padding-bottom: .75rem;
}

.tw-py-4:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.tw-py-5:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}

.tw-py-button-compact-padding-y:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  padding-top: var(--button-compact-padding-y);
  padding-bottom: var(--button-compact-padding-y);
}

.tw-py-button-regular-padding-y:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  padding-top: var(--button-regular-padding-y);
  padding-bottom: var(--button-regular-padding-y);
}

.tw-pb-4:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  padding-bottom: 1rem;
}

.tw-pb-5:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  padding-bottom: 1.25rem;
}

.tw-pb-6:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  padding-bottom: 1.5rem;
}

.tw-pl-10:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  padding-left: 2.5rem;
}

.tw-pl-4:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  padding-left: 1rem;
}

.tw-pl-8:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  padding-left: 2rem;
}

.tw-pr-16:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  padding-right: 4rem;
}

.tw-pr-8:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  padding-right: 2rem;
}

.tw-pt-4:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  padding-top: 1rem;
}

.tw-text-left:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  text-align: left;
}

.tw-text-center:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  text-align: center;
}

.tw-align-top:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  vertical-align: top;
}

.tw-font-sans:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  font-family: ui-sans-serif, system-ui, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
}

.tw-text-2xl:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  font-size: 1.5rem;
  line-height: 2rem;
}

.tw-text-4xl:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  font-size: 2.25rem;
  line-height: 2.5rem;
}

.tw-text-base:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  font-size: 1rem;
  line-height: 1.5rem;
}

.tw-text-lg:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.tw-text-sm:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  font-size: .875rem;
  line-height: 1.25rem;
}

.tw-text-xl:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.tw-text-xs:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  font-size: .75rem;
  line-height: 1rem;
}

.tw-font-bold:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  font-weight: 700;
}

.tw-font-medium:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  font-weight: 500;
}

.tw-font-semibold:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  font-weight: 600;
}

.tw-ordinal:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  --tw-ordinal: ordinal;
  font-variant-numeric: var(--tw-ordinal) var(--tw-slashed-zero) var(--tw-numeric-figure) var(--tw-numeric-spacing) var(--tw-numeric-fraction);
}

.tw-leading-none:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  line-height: 1;
}

.tw-leading-normal:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  line-height: 1.5;
}

.tw-leading-tight:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  line-height: 1.25;
}

.\!tw-text-ink-disabled:not(#\#) {
  color: var(--color-ink-disabled) !important;
}

.tw-text-base-0:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  color: var(--color-base-0);
}

.tw-text-base-400:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  color: var(--color-base-400);
}

.tw-text-base-900:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  color: var(--color-base-900);
}

.tw-text-button-primary-text:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  color: var(--color-button-primary-text);
}

.tw-text-button-secondary-text:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  color: var(--color-button-secondary-text);
}

.tw-text-button-tertiary-text:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  color: var(--color-button-tertiary-text);
}

.tw-text-ink-brand-default:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  color: var(--color-ink-brand-default);
}

.tw-text-ink-brand-inverted:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  color: var(--color-ink-brand-inverted);
}

.tw-text-ink-default:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  color: var(--color-ink-default);
}

.tw-text-ink-disabled:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  color: var(--color-ink-disabled);
}

.tw-text-link-default:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  color: var(--color-link-default);
}

.tw-text-link-inverted:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  color: var(--color-link-default-inverted);
}

.tw-text-surface-default:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  color: var(--color-surface-default);
}

.tw-underline:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  text-decoration-line: underline;
}

.tw-no-underline:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  text-decoration-line: none;
}

.tw-underline-offset-2:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  text-underline-offset: 2px;
}

.tw-underline-offset-4:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  text-underline-offset: 4px;
}

.tw-accent-surface-action:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  accent-color: var(--color-surface-action);
}

.tw-opacity-0:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  opacity: 0;
}

.tw-opacity-100:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  opacity: 1;
}

.tw-opacity-30:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  opacity: .3;
}

.tw-opacity-50:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  opacity: .5;
}

.tw-opacity-90:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  opacity: .9;
}

.tw-mix-blend-darken:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  mix-blend-mode: darken;
}

.tw-shadow:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  --tw-shadow: 0 1px 3px 0 #0000001a, 0 1px 2px -1px #0000001a;
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.tw-shadow-lg:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  --tw-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -4px #0000001a;
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.tw-shadow-md:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  --tw-shadow: 0 4px 6px -1px #0000001a, 0 2px 4px -2px #0000001a;
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.tw-outline-none:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.tw-ring:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.tw-ring-0:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.tw-ring-1:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.tw-ring-2:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.tw-ring-information-600:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  --tw-ring-color: var(--color-information-600);
}

.tw-ring-surface-default:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  --tw-ring-color: var(--color-surface-default);
}

.tw-ring-offset-2:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  --tw-ring-offset-width: 2px;
}

.tw-ring-offset-button-primary-bg:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  --tw-ring-offset-color: var(--color-button-primary-bg);
}

.tw-drop-shadow-lg:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  --tw-drop-shadow: drop-shadow(0 10px 8px #0000000a) drop-shadow(0 4px 3px #0000001a);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.tw-drop-shadow-md:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  --tw-drop-shadow: drop-shadow(0 4px 3px #00000012) drop-shadow(0 2px 2px #0000000f);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.tw-transition:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter, -webkit-backdrop-filter;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.tw-transition-all:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  transition-property: all;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.tw-transition-colors:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.tw-transition-opacity:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  transition-property: opacity;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.tw-transition-transform:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  transition-property: transform;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.tw-duration-200:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  transition-duration: .2s;
}

.tw-duration-300:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  transition-duration: .3s;
}

.tw-duration-500:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  transition-duration: .5s;
}

.tw-ease-in:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  transition-timing-function: cubic-bezier(.4, 0, 1, 1);
}

.tw-ease-in-out:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.tw-ease-out:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  transition-timing-function: cubic-bezier(0, 0, .2, 1);
}

.tw-\@container:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  container-type: inline-size;
}

.tw-text-shadow-default:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  text-shadow: 1px 1px 3px var(--color-base-900);
}

.\*\:tw-fill-link-inverted:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) > * {
  fill: var(--color-link-default-inverted);
}

.file\:tw-mr-5:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#)::file-selector-button {
  margin-right: 1.25rem;
}

.file\:tw-rounded-button:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#)::file-selector-button {
  border-radius: var(--button-border-radius);
}

.file\:tw-border-solid:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#)::file-selector-button {
  border-style: solid;
}

.file\:tw-border-button-primary-bg:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#)::file-selector-button {
  border-color: var(--color-button-primary-bg);
}

.file\:tw-bg-button-primary-bg:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#)::file-selector-button {
  background-color: var(--color-button-primary-bg);
}

.file\:tw-px-button-regular-padding-x:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#)::file-selector-button {
  padding-left: var(--button-regular-padding-x);
  padding-right: var(--button-regular-padding-x);
}

.file\:tw-py-button-regular-padding-y:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#)::file-selector-button {
  padding-top: var(--button-regular-padding-y);
  padding-bottom: var(--button-regular-padding-y);
}

.file\:tw-text-button-primary-text:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#)::file-selector-button {
  color: var(--color-button-primary-text);
}

.placeholder\:tw-text-ink-disabled:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#)::-moz-placeholder {
  color: var(--color-ink-disabled);
}

.placeholder\:tw-text-ink-disabled:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#)::placeholder {
  color: var(--color-ink-disabled);
}

.before\:tw-absolute:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):before {
  content: var(--tw-content);
  position: absolute;
}

.before\:tw-inset-0:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):before {
  content: var(--tw-content);
  inset: 0;
}

.before\:tw-z-0:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):before {
  content: var(--tw-content);
  z-index: 0;
}

.last\:tw-border-b-0:last-child:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#), .odd\:tw-border-b-0:nth-child(odd):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#), .even\:tw-border-b-0:nth-child(2n):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  border-bottom-width: 0;
}

.last-of-type\:tw-border-b:last-of-type:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  border-bottom-width: 1px;
}

.last-of-type\:tw-border-b-ink-brand-subtle:last-of-type:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  border-bottom-color: var(--color-ink-brand-subtle);
}

.checked\:tw-border-base-200:checked:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  border-color: var(--color-base-200);
}

.checked\:tw-border-surface-action:checked:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  border-color: var(--color-surface-action);
}

.checked\:tw-border-surface-default:checked:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  border-color: var(--color-surface-default);
}

.checked\:tw-bg-base-0:checked:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  background-color: var(--color-base-0);
}

.checked\:tw-bg-base-50:checked:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  background-color: var(--color-base-50);
}

.checked\:tw-bg-surface-action:checked:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  background-color: var(--color-surface-action);
}

.hover\:-tw-translate-y-1:hover:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  --tw-translate-y: -.25rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.hover\:tw-scale-110:hover:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  --tw-scale-x: 1.1;
  --tw-scale-y: 1.1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.hover\:tw-border-0:hover:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  border-width: 0;
}

.hover\:tw-border-button-primary-bg-hover:hover:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  border-color: var(--color-button-primary-bg-hover);
}

.hover\:tw-border-button-secondary-border:hover:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  border-color: var(--color-button-secondary-border);
}

.hover\:tw-border-button-tertiary-bg-hover:hover:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  border-color: var(--color-button-tertiary-bg-hover);
}

.hover\:tw-border-error-800:hover:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  border-color: var(--color-error-800);
}

.hover\:tw-border-ink-brand-default:hover:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  border-color: var(--color-ink-brand-default);
}

.hover\:tw-bg-base-600:hover:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  background-color: var(--color-base-600);
}

.hover\:tw-bg-button-disabled-bg:hover:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  background-color: var(--color-button-disabled-bg);
}

.hover\:tw-bg-button-primary-bg-hover:hover:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  background-color: var(--color-button-primary-bg-hover);
}

.hover\:tw-bg-button-secondary-bg-hover:hover:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  background-color: var(--color-button-secondary-bg-hover);
}

.hover\:tw-bg-button-tertiary-bg-hover:hover:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  background-color: var(--color-button-tertiary-bg-hover);
}

.hover\:tw-bg-chip-active-hover:hover:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  background-color: var(--color-chip-active-hover);
}

.hover\:tw-bg-chip-default-hover:hover:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  background-color: var(--color-chip-default-hover);
}

.hover\:tw-bg-error-800:hover:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  background-color: var(--color-error-800);
}

.hover\:tw-bg-surface-default-hover:hover:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  background-color: var(--color-surface-default-hover);
}

.hover\:\!tw-underline:hover:not(#\#) {
  text-decoration-line: underline !important;
}

.hover\:tw-underline:hover:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  text-decoration-line: underline;
}

.hover\:\!tw-no-underline:hover:not(#\#) {
  text-decoration-line: none !important;
}

.hover\:tw-no-underline:hover:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  text-decoration-line: none;
}

.\*\:hover\:tw-fill-link-inverted-hover:hover:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) > * {
  fill: var(--color-link-default-inverted-hover);
}

.hover\:file\:tw-cursor-pointer:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#)::file-selector-button:hover {
  cursor: pointer;
}

.focus\:tw-outline-none:focus:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.focus\:tw-ring-2:focus:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.focus\:tw-ring-information-600:focus:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  --tw-ring-color: var(--color-information-600);
}

.focus-visible\:tw-border-ink-brand-default:focus-visible:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  border-color: var(--color-ink-brand-default);
}

.focus-visible\:tw-border-link-default:focus-visible:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  border-color: var(--color-link-default);
}

.focus-visible\:tw-bg-base-0:focus-visible:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  background-color: var(--color-base-0);
}

.focus-visible\:tw-outline-none:focus-visible:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.focus-visible\:tw-ring:focus-visible:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.focus-visible\:tw-ring-2:focus-visible:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.focus-visible\:tw-ring-information-600:focus-visible:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  --tw-ring-color: var(--color-information-600);
}

.focus-visible\:tw-ring-offset-1:focus-visible:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  --tw-ring-offset-width: 1px;
}

.focus-visible\:tw-ring-offset-ink-brand-default:focus-visible:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  --tw-ring-offset-color: var(--color-ink-brand-default);
}

.disabled\:tw-cursor-not-allowed:disabled:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  cursor: not-allowed;
}

.disabled\:tw-border-button-disabled-bg:disabled:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  border-color: var(--color-button-disabled-bg);
}

.disabled\:tw-bg-button-disabled-bg:disabled:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  background-color: var(--color-button-disabled-bg);
}

.disabled\:tw-text-ink-disabled:disabled:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  color: var(--color-ink-disabled);
}

.disabled\:tw-no-underline:disabled:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  text-decoration-line: none;
}

.tw-group:hover:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) .group-hover\:tw-visible {
  visibility: visible;
}

.tw-group:hover:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) .group-hover\:tw-scale-105 {
  --tw-scale-x: 1.05;
  --tw-scale-y: 1.05;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.tw-group:hover:not(#\#) .group-hover\:\!tw-border-base-400 {
  border-color: var(--color-base-400) !important;
}

.tw-group:hover:not(#\#) .group-hover\:\!tw-border-ink-error {
  border-color: var(--color-ink-error) !important;
}

.tw-group:hover:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) .group-hover\:tw-border-surface-action {
  border-color: var(--color-surface-action);
}

.tw-group:hover:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) .group-hover\:tw-border-surface-default {
  border-color: var(--color-surface-default);
}

.tw-group:hover:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) .group-hover\:tw-bg-base-50 {
  background-color: var(--color-base-50);
}

.tw-group:hover:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) .group-hover\:tw-bg-button-tertiary-bg-hover {
  background-color: var(--color-button-tertiary-bg-hover);
}

.tw-group:hover:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) .group-hover\:tw-bg-surface-default {
  background-color: var(--color-surface-default);
}

.tw-group:hover:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) .group-hover\:tw-bg-surface-default-hover {
  background-color: var(--color-surface-default-hover);
}

.tw-group:hover:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) .group-hover\:tw-underline {
  text-decoration-line: underline;
}

.tw-group:hover:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) .group-hover\:checked\:tw-bg-surface-action:checked {
  background-color: var(--color-surface-action);
}

.tw-group:focus:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) .group-focus\:tw-visible {
  visibility: visible;
}

.tw-peer:checked:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) ~ .peer-checked\:tw-block {
  display: block;
}

.tw-peer:checked:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) ~ .peer-checked\:tw-fill-ink-brand-default {
  fill: var(--color-ink-brand-default);
}

.tw-peer:checked:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) ~ .peer-checked\:tw-fill-ink-brand-inverted {
  fill: var(--color-ink-brand-inverted);
}

.data-\[disabled\]\:tw-cursor-not-allowed[data-disabled]:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  cursor: not-allowed;
}

.data-\[invalid\]\:data-\[hover\]\:tw-border-error-600[data-hover][data-invalid]:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#), .data-\[invalid\]\:tw-border-error-600[data-invalid]:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  border-color: var(--color-error-600);
}

.tw-group[data-checked]:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) .group-data-\[checked\]\:tw-translate-x-6 {
  --tw-translate-x: 1.5rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.tw-group[data-disabled]:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) .group-data-\[disabled\]\:tw-bg-base-300 {
  background-color: var(--color-base-300);
}

@container (width >= 24rem) {
  .\@sm\:tw-line-clamp-2:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    overflow: hidden;
  }

  .\@sm\:tw-aspect-video:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
    aspect-ratio: 16 / 9;
  }

  .\@sm\:tw-w-full:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
    width: 100%;
  }

  .\@sm\:tw-flex-col:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
    flex-direction: column;
  }

  .\@sm\:tw-px-4:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .\@sm\:tw-py-5:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
  }

  .\@sm\:tw-pb-5:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
    padding-bottom: 1.25rem;
  }

  .\@sm\:tw-pt-4:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
    padding-top: 1rem;
  }

  .\@sm\:tw-text-2xl:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
    font-size: 1.5rem;
    line-height: 2rem;
  }

  .\@sm\:tw-text-lg:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
    font-size: 1.125rem;
    line-height: 1.75rem;
  }
}

@container (width >= 28rem) {
  .\@md\:tw-flex-col-reverse:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
    flex-direction: column-reverse;
  }

  .\@md\:tw-gap-6:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
    gap: 1.5rem;
  }

  .\@md\:tw-text-lg:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
    font-size: 1.125rem;
    line-height: 1.75rem;
  }
}

@container (width >= 32rem) {
  .\@lg\:tw-gap-6:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
    gap: 1.5rem;
  }

  .\@lg\:tw-text-3xl:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
    font-size: 1.875rem;
    line-height: 2.25rem;
  }
}

@container (width >= 36rem) {
  .\@xl\:tw-order-1:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
    order: 1;
  }

  .\@xl\:tw-col-span-6:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
    grid-column: span 6 / span 6;
  }

  .\@xl\:tw-line-clamp-3:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    overflow: hidden;
  }

  .\@xl\:tw-line-clamp-4:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    overflow: hidden;
  }

  .\@xl\:tw-block:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
    display: block;
  }

  .\@xl\:tw-items-start:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
    align-items: flex-start;
  }

  .\@xl\:tw-justify-center:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
    justify-content: center;
  }

  .\@xl\:tw-p-10:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
    padding: 2.5rem;
  }
}

@container (width >= 42rem) {
  .\@2xl\:tw-p-10:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
    padding: 2.5rem;
  }
}

@media not all and (width >= 1024px) {
  .max-lg\:tw-absolute:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
    position: absolute;
  }

  .max-lg\:tw-m-\[-1px\]:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
    margin: -1px;
  }

  .max-lg\:tw-grid:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
    display: grid;
  }

  .max-lg\:tw-hidden:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
    display: none;
  }

  .max-lg\:tw-h-\[1px\]:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
    height: 1px;
  }

  .max-lg\:tw-w-\[1px\]:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
    width: 1px;
  }

  .max-lg\:tw-w-full:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
    width: 100%;
  }

  .max-lg\:tw-grid-cols-1:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }

  .max-lg\:tw-grid-cols-2:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .max-lg\:tw-justify-items-end:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
    justify-items: end;
  }

  .max-lg\:tw-overflow-hidden:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
    overflow: hidden;
  }

  .max-lg\:tw-text-clip:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
    text-overflow: clip;
  }

  .max-lg\:tw-border:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
    border-width: 1px;
  }

  .max-lg\:tw-border-transparent:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
    border-color: #0000;
  }

  .max-lg\:tw-border-b-ink-border-subtle:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
    border-bottom-color: var(--color-ink-border-subtle);
  }

  .max-lg\:tw-font-medium:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
    font-weight: 500;
  }

  .max-lg\:before\:tw-mr-1:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):before {
    content: var(--tw-content);
    margin-right: .25rem;
  }

  .max-lg\:before\:tw-hyphens-auto:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):before {
    content: var(--tw-content);
    -webkit-hyphens: auto;
    hyphens: auto;
  }

  .max-lg\:before\:tw-font-normal:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):before {
    content: var(--tw-content);
    font-weight: 400;
  }

  .max-lg\:before\:tw-text-ink-brand-default:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):before {
    content: var(--tw-content);
    color: var(--color-ink-brand-default);
  }

  .max-lg\:before\:tw-content-\[attr\(headers\)\]:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):before {
    --tw-content: attr(headers);
    content: var(--tw-content);
  }

  .max-lg\:before\:tw-content-none:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):before {
    --tw-content: none;
    content: var(--tw-content);
  }

  .max-lg\:last\:tw-border-b-0:last-child:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
    border-bottom-width: 0;
  }

  .even\:max-lg\:tw-border-t:nth-child(2n):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
    border-top-width: 1px;
  }

  .even\:max-lg\:tw-border-t-ink-border-subtle:nth-child(2n):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
    border-top-color: var(--color-ink-border-subtle);
  }
}

@media (width >= 640px) {
  .sm\:tw-w-\[1000px\]:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
    width: 1000px;
  }

  .sm\:tw-w-\[400px\]:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
    width: 400px;
  }

  .sm\:tw-w-\[600px\]:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
    width: 600px;
  }

  .sm\:tw-w-\[800px\]:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
    width: 800px;
  }
}

@media (width >= 768px) {
  .md\:tw-block:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
    display: block;
  }

  .md\:tw-hidden:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
    display: none;
  }

  .md\:tw-w-\[1000px\]:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
    width: 1000px;
  }

  .md\:tw-w-\[1200px\]:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
    width: 1200px;
  }

  .md\:tw-w-\[800px\]:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
    width: 800px;
  }

  .md\:tw-max-w-3xl:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
    max-width: 48rem;
  }

  .md\:tw-max-w-4xl:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
    max-width: 56rem;
  }

  .md\:tw-max-w-lg:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
    max-width: 32rem;
  }

  .md\:tw-max-w-md:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
    max-width: 28rem;
  }

  .md\:tw-max-w-xl:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
    max-width: 36rem;
  }

  .md\:tw-snap-x:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
    scroll-snap-type: x var(--tw-scroll-snap-strictness);
  }

  .md\:tw-snap-mandatory:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
    --tw-scroll-snap-strictness: mandatory;
  }

  .md\:tw-snap-center:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
    scroll-snap-align: center;
  }

  .tw-group:hover:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) .md\:group-hover\:tw-translate-x-0 {
    --tw-translate-x: 0px;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .tw-group:hover:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) .md\:group-hover\:tw-translate-x-\[20px\] {
    --tw-translate-x: 20px;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }
}

.\[\&\:disabled\]\:tw-bg-surface-default:disabled:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  background-color: var(--color-surface-default);
}

.\[\&\:has\(\:focus-visible\)\]\:tw-ring:has(:focus-visible):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.\[\&\:has\(\:focus-visible\)\]\:tw-ring-2:has(:focus-visible):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.\[\&\>\*\:nth-last-child\(2\)\]\:tw-inline-block:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) > :nth-last-child(2) {
  display: inline-block;
}

.\[\&\>\*\]\:tw-hidden:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) > * {
  display: none;
}

.\[\&\>\*\]\:tw-grow:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) > * {
  flex-grow: 1;
}

@media (width >= 768px) {
  .md\:\[\&\>\*\]\:tw-flex:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) > * {
    display: flex;
  }
}

.\[\&\>div\]\:tw-h-full:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) > div {
  height: 100%;
}

.\[\&\>li\:nth-last-child\(2\)\>div\>svg\]\:tw-rotate-180:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) > li:nth-last-child(2) > div > svg {
  --tw-rotate: 180deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

@media (width >= 768px) {
  .md\:\[\&\>li\:nth-last-child\(2\)\>div\>svg\]\:tw-rotate-0:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) > li:nth-last-child(2) > div > svg {
    --tw-rotate: 0deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }
}

.\[\&\>svg\]\:-tw-m-0\.5:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) > svg {
  margin: -.125rem;
}

.\[\&\>svg\]\:-tw-m-1:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) > svg {
  margin: -.25rem;
}

.\[\&\>svg\]\:tw-inline:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) > svg {
  display: inline;
}

.\[\&\>svg\]\:tw-size-5:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) > svg {
  width: 1.25rem;
  height: 1.25rem;
}

.\[\&\>svg\]\:tw-h-\[20px\]:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) > svg {
  height: 20px;
}

.\[\&\>svg\]\:tw-w-\[20px\]:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) > svg {
  width: 20px;
}

.\[\&\>svg\]\:tw-flex-shrink-0:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) > svg {
  flex-shrink: 0;
}

.\[\&\>svg\]\:tw-ring:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) > svg {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.\[\&\>svg\]\:disabled\:tw-fill-ink-disabled:disabled:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) > svg {
  fill: var(--color-ink-disabled);
}

.\[\&\>thead\]\:tw-sticky:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) > thead {
  position: sticky;
}

.\[\&\>thead\]\:tw-top-0:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) > thead {
  top: 0;
}

.\[\&\>thead\]\:tw-z-10:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) > thead {
  z-index: 10;
}

.\[\&\>thead\]\:tw-border-b-ink-border-subtle:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) > thead {
  border-bottom-color: var(--color-ink-border-subtle);
}

.\[\&_input\]\:tw-h-8:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) input {
  height: 2rem;
}

.\[\&_input\]\:tw-h-full:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) input {
  height: 100%;
}

.\[\&_input\]\:tw-rounded-none:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) input {
  border-radius: 0;
}

.\[\&_input\]\:tw-border-0:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) input {
  border-width: 0;
}

.\[\&_input\]\:tw-px-0:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) input {
  padding-left: 0;
  padding-right: 0;
}

.\[\&_input\]\:tw-text-center:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) input {
  text-align: center;
}

.\[\&_input\]\:tw-text-sm:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) input {
  font-size: .875rem;
  line-height: 1.25rem;
}

.\[\&_input\]\:tw-text-xl:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) input {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.\[\&_input\]\:tw-font-bold:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) input {
  font-weight: 700;
}

.\[\&_input\]\:focus\:tw-border-0:focus:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) input, .\[\&_input\]\:active\:tw-border-0:active:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) input {
  border-width: 0;
}

@media not all and (width >= 1024px) {
  .max-lg\:first-of-type\:\[\&_td\]\:tw-border-\[1\.5px\]:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) td:first-of-type {
    border-width: 1.5px;
  }

  .max-lg\:first-of-type\:\[\&_td\]\:tw-bg-surface-default-hover:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) td:first-of-type {
    background-color: var(--color-surface-default-hover);
  }
}

:root:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#), :root.isdalen:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#), .isdalen:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#), [data-ds-theme="isdalen"]:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  --border-radius-0: 0rem;
  --border-radius-1: .25rem;
  --border-radius-2: .5rem;
  --border-radius-3: .75rem;
  --border-radius-6: 1.5rem;
  --border-radius-default: .25rem;
  --border-radius-full: 624.938rem;
  --color-base-0: #fff;
  --color-base-25: #fafafa;
  --color-base-50: #f5f5f5;
  --color-base-100: #ebebeb;
  --color-base-200: #d9d9d9;
  --color-base-300: #cacaca;
  --color-base-400: #9d9d9d;
  --color-base-500: #7b7b7b;
  --color-base-600: #555;
  --color-base-700: #393939;
  --color-base-800: #222;
  --color-base-900: #000;
  --color-base-transparent: #ffffff03;
  --color-error-100: #fee2e2;
  --color-error-200: #fecaca;
  --color-error-600: #dc2626;
  --color-error-700: #b91c1c;
  --color-error-800: #991b1b;
  --color-information-100: #dbeafe;
  --color-information-200: #bfdbfe;
  --color-information-600: #2563eb;
  --color-success-100: #dcfce7;
  --color-success-200: #bbf7d0;
  --color-success-600: #16a34a;
  --color-violet-100: #ede9fe;
  --color-violet-600: #7c3aed;
  --color-warning-100: #fef3c7;
  --color-warning-200: #fde68a;
  --color-warning-600: #d97706;
  --color-background-default: #fff;
  --color-background-default-inverted: #31353d;
  --color-background-subtle: #f2f2f2;
  --color-button-danger-bg: #b91c1c;
  --color-button-danger-bg-hover: #991b1b;
  --color-button-disabled-bg: #ebebeb;
  --color-button-primary-bg: #31353d;
  --color-button-primary-bg-hover: #55585f;
  --color-button-primary-text: #fff;
  --color-button-secondary-bg: #fff;
  --color-button-secondary-bg-hover: #b5e5f6;
  --color-button-secondary-border: #31353d;
  --color-button-secondary-text: #31353d;
  --color-button-tertiary-bg: #ffffff03;
  --color-button-tertiary-bg-hover: #b5e5f6;
  --color-button-tertiary-text: #31353d;
  --color-chip-active: #31353d;
  --color-chip-active-hover: #55585f;
  --color-chip-default: #e2f5fb;
  --color-chip-default-hover: #b5e5f6;
  --color-ink-border-default: #867f98;
  --color-ink-border-subtle: #867f98;
  --color-ink-brand-default: #31353d;
  --color-ink-brand-inverted: #fff;
  --color-ink-brand-subtle: #55585f;
  --color-ink-default: #222;
  --color-ink-disabled: #7b7b7b;
  --color-ink-error: #dc2626;
  --color-ink-focus: #2563eb;
  --color-ink-focus-inverted: #dbeafe;
  --color-ink-information: #2563eb;
  --color-ink-subtle: #393939;
  --color-ink-success: #16a34a;
  --color-ink-warning: #d97706;
  --color-link-default: #222;
  --color-link-default-hover: #31353d;
  --color-link-default-inverted: #fff;
  --color-link-default-inverted-hover: #fff;
  --color-link-disabled: #7b7b7b;
  --color-surface-action: #31353d;
  --color-surface-default: #fff;
  --color-surface-default-hover: #e2f5fb;
  --color-surface-default-inverted: #31353d;
  --color-surface-error: #fee2e2;
  --color-surface-information: #dbeafe;
  --color-surface-success: #dcfce7;
  --color-surface-tip: #ede9fe;
  --color-surface-warning: #fef3c7;
  --color-cyan-50: #e2f5fb;
  --color-cyan-100: #b5e5f6;
  --color-cyan-400: #44b6e6;
  --color-metal-100: #f2f2f2;
  --color-metal-600: #867f98;
  --color-metal-800: #55585f;
  --color-metal-900: #31353d;
  --color-red-700: #d52b33;
  --screen-xs: 320px;
  --screen-sm: 640px;
  --screen-md: 768px;
  --screen-lg: 1024px;
  --screen-xl: 1280px;
  --screen-2xl: 1536px;
  --spacing-0: 0rem;
  --spacing-1: .25rem;
  --spacing-2: .5rem;
  --spacing-3: .75rem;
  --spacing-4: 1rem;
  --spacing-5: 1.25rem;
  --spacing-6: 1.5rem;
  --spacing-7: 1.75rem;
  --spacing-8: 2rem;
  --spacing-9: 2.25rem;
  --spacing-10: 2.5rem;
  --spacing-11: 2.75rem;
  --spacing-12: 3rem;
  --spacing-14: 3.5rem;
  --spacing-16: 4rem;
  --spacing-24: 6rem;
  --button-compact-padding-y: .5rem;
  --button-compact-padding-x: .5rem;
  --button-regular-padding-y: .75rem;
  --button-regular-padding-x: 1rem;
  --button-border-radius: .25rem;
  --card-border-radius-lg: .5rem;
  --card-border-radius-md: .5rem;
  --card-border-radius-sm: .25rem;
}

*, :before, :after {
  box-sizing: border-box;
  border: 0 solid;
}

:before, :after {
  --tw-content: "";
}

html, :host {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-feature-settings: normal;
  font-variation-settings: normal;
  -webkit-tap-highlight-color: transparent;
  font-family: ui-sans-serif, system-ui, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  color: inherit;
  border-top-width: 1px;
  height: 0;
}

abbr:where([title]) {
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-feature-settings: normal;
  font-variation-settings: normal;
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-feature-settings: inherit;
  font-variation-settings: inherit;
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  letter-spacing: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, input:where([type="button"]), input:where([type="reset"]), input:where([type="submit"]) {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button {
  height: auto;
}

::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

dialog {
  padding: 0;
}

textarea {
  resize: vertical;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

[hidden] {
  display: none;
}

*, :before, :after, ::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position: ;
  --tw-gradient-via-position: ;
  --tw-gradient-to-position: ;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
  --tw-contain-size: ;
  --tw-contain-layout: ;
  --tw-contain-paint: ;
  --tw-contain-style: ;
}

.tw-sr-only:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

.tw-pointer-events-none:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  pointer-events: none;
}

.tw-pointer-events-auto:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  pointer-events: auto;
}

.tw-visible:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  visibility: visible;
}

.tw-invisible:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  visibility: hidden;
}

.tw-fixed:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  position: fixed;
}

.tw-absolute:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  position: absolute;
}

.tw-relative:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  position: relative;
}

.tw-sticky:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  position: sticky;
}

.tw-inset-0:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  inset: 0;
}

.-tw-left-16:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  left: -4rem;
}

.-tw-right-16:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  right: -4rem;
}

.-tw-right-\[3px\]:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  right: -3px;
}

.tw--right-2:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  right: -.5rem;
}

.tw-bottom-0:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  bottom: 0;
}

.tw-bottom-2:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  bottom: .5rem;
}

.tw-bottom-5:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  bottom: 1.25rem;
}

.tw-bottom-6:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  bottom: 1.5rem;
}

.tw-bottom-\[--popover-arrow-displacement\]:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  bottom: var(--popover-arrow-displacement);
}

.tw-bottom-\[-6px\]:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  bottom: -6px;
}

.tw-bottom-\[10\%\]:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  bottom: 10%;
}

.tw-bottom-full:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  bottom: 100%;
}

.tw-left-0:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  left: 0;
}

.tw-left-1\/2:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  left: 50%;
}

.tw-left-2:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  left: .5rem;
}

.tw-left-3:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  left: .75rem;
}

.tw-left-6:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  left: 1.5rem;
}

.tw-left-7:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  left: 1.75rem;
}

.tw-left-\[--popover-arrow-displacement\]:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  left: var(--popover-arrow-displacement);
}

.tw-left-\[-12px\]:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  left: -12px;
}

.tw-left-\[10\%\]:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  left: 10%;
}

.tw-left-\[50\%\]:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  left: 50%;
}

.tw-left-\[calc\(50\%-45px\)\]:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  left: calc(50% - 45px);
}

.tw-right-0:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  right: 0;
}

.tw-right-2:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  right: .5rem;
}

.tw-right-7:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  right: 1.75rem;
}

.tw-right-8:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  right: 2rem;
}

.tw-right-\[--popover-arrow-displacement\]:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  right: var(--popover-arrow-displacement);
}

.tw-right-\[-12px\]:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  right: -12px;
}

.tw-right-\[-7px\]:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  right: -7px;
}

.tw-top-0:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  top: 0;
}

.tw-top-1\/2:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  top: 50%;
}

.tw-top-2:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  top: .5rem;
}

.tw-top-3:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  top: .75rem;
}

.tw-top-6:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  top: 1.5rem;
}

.tw-top-7:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  top: 1.75rem;
}

.tw-top-8:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  top: 2rem;
}

.tw-top-\[--popover-arrow-displacement\]:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  top: var(--popover-arrow-displacement);
}

.tw-top-\[-22px\]:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  top: -22px;
}

.tw-top-\[-2px\]:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  top: -2px;
}

.tw-top-\[-6px\]:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  top: -6px;
}

.tw-top-\[25\%\]:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  top: 25%;
}

.tw-top-\[50\%\]:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  top: 50%;
}

.\!tw-z-0 {
  z-index: 0 !important;
}

.\!tw-z-10 {
  z-index: 10 !important;
}

.\!tw-z-30 {
  z-index: 30 !important;
}

.\!tw-z-40 {
  z-index: 40 !important;
}

.\!tw-z-50 {
  z-index: 50 !important;
}

.\!tw-z-auto {
  z-index: auto !important;
}

.-tw-z-10:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  z-index: -10;
}

.tw-z-0:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  z-index: 0;
}

.tw-z-10:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  z-index: 10;
}

.tw-z-20:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  z-index: 20;
}

.tw-z-30:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  z-index: 30;
}

.tw-z-40:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  z-index: 40;
}

.tw-z-50:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  z-index: 50;
}

.tw-z-\[1000\]:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  z-index: 1000;
}

.tw-z-\[200\]:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  z-index: 200;
}

.tw-col-span-full:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  grid-column: 1 / -1;
}

.tw-float-end:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  float: inline-end;
}

.tw-m-0:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  margin: 0;
}

.tw-m-1:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  margin: .25rem;
}

.tw-m-12:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  margin: 3rem;
}

.tw-m-2:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  margin: .5rem;
}

.tw-m-3:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  margin: .75rem;
}

.tw-m-4:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  margin: 1rem;
}

.tw-m-6:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  margin: 1.5rem;
}

.tw-m-auto:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  margin: auto;
}

.tw-m-px:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  margin: 1px;
}

.-tw-my-2:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  margin-top: -.5rem;
  margin-bottom: -.5rem;
}

.tw-mx-0:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  margin-left: 0;
  margin-right: 0;
}

.tw-mx-2:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  margin-left: .5rem;
  margin-right: .5rem;
}

.tw-mx-auto:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  margin-left: auto;
  margin-right: auto;
}

.tw-my-14:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  margin-top: 3.5rem;
  margin-bottom: 3.5rem;
}

.tw-my-16:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  margin-top: 4rem;
  margin-bottom: 4rem;
}

.tw-my-2:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  margin-top: .5rem;
  margin-bottom: .5rem;
}

.tw-my-3:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  margin-top: .75rem;
  margin-bottom: .75rem;
}

.tw-my-4:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.tw-my-5:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
}

.tw-my-6:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.tw-my-8:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.\!tw-mb-0 {
  margin-bottom: 0 !important;
}

.-tw-mb-10:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  margin-bottom: -2.5rem;
}

.-tw-mb-\[2px\]:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  margin-bottom: -2px;
}

.-tw-me-2:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  margin-inline-end: -.5rem;
}

.-tw-mr-4:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  margin-right: -1rem;
}

.-tw-mt-8:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  margin-top: -2rem;
}

.-tw-mt-\[1px\]:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  margin-top: -1px;
}

.tw-mb-0:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  margin-bottom: 0;
}

.tw-mb-0\.5:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  margin-bottom: .125rem;
}

.tw-mb-1:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  margin-bottom: .25rem;
}

.tw-mb-10:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  margin-bottom: 2.5rem;
}

.tw-mb-12:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  margin-bottom: 3rem;
}

.tw-mb-14:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  margin-bottom: 3.5rem;
}

.tw-mb-16:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  margin-bottom: 4rem;
}

.tw-mb-2:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  margin-bottom: .5rem;
}

.tw-mb-3:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  margin-bottom: .75rem;
}

.tw-mb-4:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  margin-bottom: 1rem;
}

.tw-mb-5:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  margin-bottom: 1.25rem;
}

.tw-mb-6:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  margin-bottom: 1.5rem;
}

.tw-mb-8:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  margin-bottom: 2rem;
}

.tw-mb-\[--popover-arrow-height\]:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  margin-bottom: var(--popover-arrow-height);
}

.tw-me-2:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  margin-inline-end: .5rem;
}

.tw-me-4:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  margin-inline-end: 1rem;
}

.tw-ml-1:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  margin-left: .25rem;
}

.tw-ml-12:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  margin-left: 3rem;
}

.tw-ml-16:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  margin-left: 4rem;
}

.tw-ml-2:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  margin-left: .5rem;
}

.tw-ml-3:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  margin-left: .75rem;
}

.tw-ml-\[--popover-arrow-height\]:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  margin-left: var(--popover-arrow-height);
}

.tw-ml-\[-2\.4px\]:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  margin-left: -2.4px;
}

.tw-ml-auto:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  margin-left: auto;
}

.tw-mr-1:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  margin-right: .25rem;
}

.tw-mr-2:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  margin-right: .5rem;
}

.tw-mr-3:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  margin-right: .75rem;
}

.tw-mr-4:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  margin-right: 1rem;
}

.tw-mr-5:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  margin-right: 1.25rem;
}

.tw-mr-6:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  margin-right: 1.5rem;
}

.tw-mr-7:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  margin-right: 1.75rem;
}

.tw-mr-\[--popover-arrow-height\]:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  margin-right: var(--popover-arrow-height);
}

.tw-mr-auto:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  margin-right: auto;
}

.tw-ms-0:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  margin-inline-start: 0;
}

.tw-ms-1:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  margin-inline-start: .25rem;
}

.tw-mt-1:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  margin-top: .25rem;
}

.tw-mt-10:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  margin-top: 2.5rem;
}

.tw-mt-12:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  margin-top: 3rem;
}

.tw-mt-2:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  margin-top: .5rem;
}

.tw-mt-3:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  margin-top: .75rem;
}

.tw-mt-4:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  margin-top: 1rem;
}

.tw-mt-5:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  margin-top: 1.25rem;
}

.tw-mt-6:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  margin-top: 1.5rem;
}

.tw-mt-8:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  margin-top: 2rem;
}

.tw-mt-\[--popover-arrow-height\]:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  margin-top: var(--popover-arrow-height);
}

.tw-mt-\[6px\]:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  margin-top: 6px;
}

.tw-line-clamp-2:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
}

.tw-block:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  display: block;
}

.tw-inline-block:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  display: inline-block;
}

.tw-inline:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  display: inline;
}

.tw-flex:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  display: flex;
}

.tw-inline-flex:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  display: inline-flex;
}

.tw-table-cell:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  display: table-cell;
}

.tw-grid:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  display: grid;
}

.tw-hidden:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  display: none;
}

.tw-aspect-\[1\/2\]:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  aspect-ratio: 1 / 2;
}

.tw-aspect-\[1\/3\]:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  aspect-ratio: 1 / 3;
}

.tw-aspect-\[1\/4\]:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  aspect-ratio: 1 / 4;
}

.tw-aspect-\[2\/3\]:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  aspect-ratio: 2 / 3;
}

.tw-aspect-\[4\/1\]:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  aspect-ratio: 4;
}

.tw-aspect-\[4\/2\]:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  aspect-ratio: 4 / 2;
}

.tw-aspect-\[4\/3\]:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  aspect-ratio: 4 / 3;
}

.tw-aspect-\[4\/5\]:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  aspect-ratio: 4 / 5;
}

.tw-aspect-\[4\/7\]:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  aspect-ratio: 4 / 7;
}

.tw-aspect-square:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  aspect-ratio: 1;
}

.tw-aspect-video:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  aspect-ratio: 16 / 9;
}

.tw-size-10:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  width: 2.5rem;
  height: 2.5rem;
}

.tw-size-12:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  width: 3rem;
  height: 3rem;
}

.tw-size-14:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  width: 3.5rem;
  height: 3.5rem;
}

.tw-size-16:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  width: 4rem;
  height: 4rem;
}

.tw-size-24:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  width: 6rem;
  height: 6rem;
}

.tw-size-32:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  width: 8rem;
  height: 8rem;
}

.tw-size-4:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  width: 1rem;
  height: 1rem;
}

.tw-size-5:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  width: 1.25rem;
  height: 1.25rem;
}

.tw-size-6:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  width: 1.5rem;
  height: 1.5rem;
}

.tw-size-8:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  width: 2rem;
  height: 2rem;
}

.tw-size-fit:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  width: fit-content;
  height: fit-content;
}

.tw-size-full:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  width: 100%;
  height: 100%;
}

.tw-h-1:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  height: .25rem;
}

.tw-h-10:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  height: 2.5rem;
}

.tw-h-12:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  height: 3rem;
}

.tw-h-24:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  height: 6rem;
}

.tw-h-3:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  height: .75rem;
}

.tw-h-4:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  height: 1rem;
}

.tw-h-5:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  height: 1.25rem;
}

.tw-h-6:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  height: 1.5rem;
}

.tw-h-7:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  height: 1.75rem;
}

.tw-h-8:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  height: 2rem;
}

.tw-h-9:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  height: 2.25rem;
}

.tw-h-\[--popover-arrow-height\]:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  height: var(--popover-arrow-height);
}

.tw-h-\[--popover-arrow-width\]:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  height: var(--popover-arrow-width);
}

.tw-h-\[120\%\]:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  height: 120%;
}

.tw-h-\[24px\]:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  height: 24px;
}

.tw-h-\[32px\]:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  height: 32px;
}

.tw-h-\[42px\]:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  height: 42px;
}

.tw-h-\[48px\]:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  height: 48px;
}

.tw-h-\[500px\]:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  height: 500px;
}

.tw-h-\[51px\]:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  height: 51px;
}

.tw-h-\[73px\]:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  height: 73px;
}

.tw-h-\[75px\]:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  height: 75px;
}

.tw-h-auto:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  height: auto;
}

.tw-h-fit:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  height: fit-content;
}

.tw-h-full:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  height: 100%;
}

.tw-h-screen:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  height: 100vh;
}

.tw-max-h-0:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  max-height: 0;
}

.tw-max-h-\[250px\]:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  max-height: 250px;
}

.tw-max-h-\[65vh\]:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  max-height: 65vh;
}

.tw-max-h-\[90vh\]:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  max-height: 90vh;
}

.tw-min-h-6:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  min-height: 1.5rem;
}

.tw-min-h-60:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  min-height: 15rem;
}

.tw-min-h-9:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  min-height: 2.25rem;
}

.tw-min-h-\[90vh\]:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  min-height: 90vh;
}

.tw-min-h-full:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  min-height: 100%;
}

.tw-w-1:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  width: .25rem;
}

.tw-w-1\/12:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  width: 8.33333%;
}

.tw-w-1\/2:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  width: 50%;
}

.tw-w-1\/3:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  width: 33.3333%;
}

.tw-w-1\/4:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  width: 25%;
}

.tw-w-10:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  width: 2.5rem;
}

.tw-w-12:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  width: 3rem;
}

.tw-w-14:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  width: 3.5rem;
}

.tw-w-16:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  width: 4rem;
}

.tw-w-2\/3:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  width: 66.6667%;
}

.tw-w-24:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  width: 6rem;
}

.tw-w-28:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  width: 7rem;
}

.tw-w-3:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  width: .75rem;
}

.tw-w-3\/12:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  width: 25%;
}

.tw-w-3\/4:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  width: 75%;
}

.tw-w-32:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  width: 8rem;
}

.tw-w-36:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  width: 9rem;
}

.tw-w-4:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  width: 1rem;
}

.tw-w-40:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  width: 10rem;
}

.tw-w-44:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  width: 11rem;
}

.tw-w-48:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  width: 12rem;
}

.tw-w-5:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  width: 1.25rem;
}

.tw-w-52:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  width: 13rem;
}

.tw-w-6:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  width: 1.5rem;
}

.tw-w-7:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  width: 1.75rem;
}

.tw-w-7\/12:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  width: 58.3333%;
}

.tw-w-72:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  width: 18rem;
}

.tw-w-8:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  width: 2rem;
}

.tw-w-80:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  width: 20rem;
}

.tw-w-96:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  width: 24rem;
}

.tw-w-\[--account-menu-width\]:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  width: var(--account-menu-width);
}

.tw-w-\[--popover-arrow-height\]:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  width: var(--popover-arrow-height);
}

.tw-w-\[--popover-arrow-width\]:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  width: var(--popover-arrow-width);
}

.tw-w-\[100vw\]:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  width: 100vw;
}

.tw-w-\[210px\]:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  width: 210px;
}

.tw-w-\[24px\]:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  width: 24px;
}

.tw-w-\[30\%\]:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  width: 30%;
}

.tw-w-\[32px\]:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  width: 32px;
}

.tw-w-\[36\%\]:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  width: 36%;
}

.tw-w-\[400px\]:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  width: 400px;
}

.tw-w-\[48px\]:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  width: 48px;
}

.tw-w-\[90px\]:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  width: 90px;
}

.tw-w-\[calc\(100vw-15px\)\]:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  width: calc(100vw - 15px);
}

.tw-w-\[min\(90vw\,576px\)\]:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  width: min(90vw, 576px);
}

.tw-w-auto:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  width: auto;
}

.tw-w-fit:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  width: fit-content;
}

.tw-w-full:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  width: 100%;
}

.tw-w-max:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  width: max-content;
}

.tw-w-screen:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  width: 100vw;
}

.tw-min-w-12:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  min-width: 3rem;
}

.tw-min-w-16:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  min-width: 4rem;
}

.tw-min-w-20:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  min-width: 5rem;
}

.tw-min-w-24:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  min-width: 6rem;
}

.tw-min-w-32:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  min-width: 8rem;
}

.tw-min-w-36:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  min-width: 9rem;
}

.tw-min-w-40:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  min-width: 10rem;
}

.tw-min-w-9:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  min-width: 2.25rem;
}

.tw-min-w-96:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  min-width: 24rem;
}

.tw-min-w-\[140px\]:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  min-width: 140px;
}

.tw-min-w-\[80vw\]:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  min-width: 80vw;
}

.tw-min-w-fit:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  min-width: fit-content;
}

.tw-max-w-20:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  max-width: 5rem;
}

.tw-max-w-24:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  max-width: 6rem;
}

.tw-max-w-2xl:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  max-width: 42rem;
}

.tw-max-w-36:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  max-width: 9rem;
}

.tw-max-w-3xl:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  max-width: 48rem;
}

.tw-max-w-40:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  max-width: 10rem;
}

.tw-max-w-44:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  max-width: 11rem;
}

.tw-max-w-48:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  max-width: 12rem;
}

.tw-max-w-4xl:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  max-width: 56rem;
}

.tw-max-w-56:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  max-width: 14rem;
}

.tw-max-w-96:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  max-width: 24rem;
}

.tw-max-w-\[--barcode-container-max-width\]:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  max-width: var(--barcode-container-max-width);
}

.tw-max-w-\[--main-column-max-width\]:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  max-width: var(--main-column-max-width);
}

.tw-max-w-\[500px\]:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  max-width: 500px;
}

.tw-max-w-\[600px\]:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  max-width: 600px;
}

.tw-max-w-\[680px\]:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  max-width: 680px;
}

.tw-max-w-\[800px\]:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  max-width: 800px;
}

.tw-max-w-\[848px\]:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  max-width: 848px;
}

.tw-max-w-lg:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  max-width: 32rem;
}

.tw-max-w-max:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  max-width: max-content;
}

.tw-max-w-md:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  max-width: 28rem;
}

.tw-max-w-none:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  max-width: none;
}

.tw-max-w-sm:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  max-width: 24rem;
}

.tw-max-w-xl:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  max-width: 36rem;
}

.tw-flex-1:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  flex: 1;
}

.tw-flex-auto:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  flex: auto;
}

.tw-flex-shrink:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  flex-shrink: 1;
}

.tw-flex-shrink-0:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  flex-shrink: 0;
}

.tw-shrink:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  flex-shrink: 1;
}

.tw-shrink-0:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  flex-shrink: 0;
}

.tw-flex-grow:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  flex-grow: 1;
}

.tw-flex-grow-0:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  flex-grow: 0;
}

.tw-grow:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  flex-grow: 1;
}

.tw-grow-0:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  flex-grow: 0;
}

.tw-basis-0:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  flex-basis: 0;
}

.tw-basis-1:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  flex-basis: .25rem;
}

.tw-basis-1\/3:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  flex-basis: 33.3333%;
}

.tw-basis-full:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  flex-basis: 100%;
}

.tw-table-fixed:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  table-layout: fixed;
}

.tw-border-collapse:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  border-collapse: collapse;
}

.tw-origin-left:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  transform-origin: 0;
}

.tw-origin-top:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  transform-origin: top;
}

.-tw-translate-x-0\.5:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  --tw-translate-x: -.125rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-tw-translate-x-1\/2:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  --tw-translate-x: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-tw-translate-x-full:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  --tw-translate-x: -100%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-tw-translate-y-1\/2:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  --tw-translate-y: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-tw-translate-y-4:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  --tw-translate-y: -1rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.tw--translate-x-1\/2:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  --tw-translate-x: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.tw--translate-y-1\/2:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  --tw-translate-y: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.tw-translate-x-0:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  --tw-translate-x: 0px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.tw-translate-x-1\/2:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  --tw-translate-x: 50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.tw-translate-x-\[22px\]:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  --tw-translate-x: 22px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.tw-translate-x-full:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  --tw-translate-x: 100%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.tw-translate-y-0:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  --tw-translate-y: 0px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.tw-translate-y-2:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  --tw-translate-y: .5rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.tw-rotate-0:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  --tw-rotate: 0deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.tw-rotate-180:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  --tw-rotate: 180deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.tw-rotate-90:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  --tw-rotate: 90deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.tw-rotate-\[270deg\]:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  --tw-rotate: 270deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.tw-scale-100:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.tw-scale-95:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  --tw-scale-x: .95;
  --tw-scale-y: .95;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.tw-transform:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.tw-animate-spin:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  animation: 1s linear infinite tw-spin;
}

.\!tw-cursor-not-allowed {
  cursor: not-allowed !important;
}

.tw-cursor-default:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  cursor: default;
}

.tw-cursor-grab:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  cursor: grab;
}

.tw-cursor-not-allowed:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  cursor: not-allowed;
}

.tw-cursor-pointer:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  cursor: pointer;
}

.tw-select-none:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  -webkit-user-select: none;
  user-select: none;
}

.tw-resize-y:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  resize: vertical;
}

.tw-scroll-mt-10:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  scroll-margin-top: 2.5rem;
}

.tw-list-outside:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  list-style-position: outside;
}

.tw-list-decimal:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  list-style-type: decimal;
}

.tw-list-disc:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  list-style-type: disc;
}

.tw-list-none:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  list-style-type: none;
}

.tw-appearance-none:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  -webkit-appearance: none;
  appearance: none;
}

.tw-grid-cols-1:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  grid-template-columns: repeat(1, minmax(0, 1fr));
}

.tw-grid-cols-12:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  grid-template-columns: repeat(12, minmax(0, 1fr));
}

.tw-grid-cols-2:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

.tw-grid-cols-3:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  grid-template-columns: repeat(3, minmax(0, 1fr));
}

.tw-grid-cols-4:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  grid-template-columns: repeat(4, minmax(0, 1fr));
}

.tw-grid-cols-\[1fr_fit-content\(60px\)\]:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  grid-template-columns: 1fr fit-content(60px);
}

.tw-grid-cols-\[max-content_auto_max-content\]:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  grid-template-columns: max-content auto max-content;
}

.tw-flex-row:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  flex-direction: row;
}

.tw-flex-row-reverse:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  flex-direction: row-reverse;
}

.tw-flex-col:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  flex-direction: column;
}

.tw-flex-col-reverse:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  flex-direction: column-reverse;
}

.tw-flex-wrap:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  flex-wrap: wrap;
}

.tw-flex-wrap-reverse:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  flex-wrap: wrap-reverse;
}

.tw-flex-nowrap:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  flex-wrap: nowrap;
}

.tw-items-start:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  align-items: flex-start;
}

.tw-items-end:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  align-items: flex-end;
}

.tw-items-center:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  align-items: center;
}

.tw-items-stretch:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  align-items: stretch;
}

.tw-justify-start:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  justify-content: flex-start;
}

.tw-justify-end:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  justify-content: flex-end;
}

.tw-justify-center:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  justify-content: center;
}

.tw-justify-between:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  justify-content: space-between;
}

.tw-justify-around:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  justify-content: space-around;
}

.tw-justify-evenly:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  justify-content: space-evenly;
}

.tw-justify-stretch:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  justify-content: stretch;
}

.tw-gap-0:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  gap: 0;
}

.tw-gap-1:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  gap: .25rem;
}

.tw-gap-10:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  gap: 2.5rem;
}

.tw-gap-12:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  gap: 3rem;
}

.tw-gap-16:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  gap: 4rem;
}

.tw-gap-2:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  gap: .5rem;
}

.tw-gap-20:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  gap: 5rem;
}

.tw-gap-24:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  gap: 6rem;
}

.tw-gap-3:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  gap: .75rem;
}

.tw-gap-32:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  gap: 8rem;
}

.tw-gap-4:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  gap: 1rem;
}

.tw-gap-40:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  gap: 10rem;
}

.tw-gap-48:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  gap: 12rem;
}

.tw-gap-5:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  gap: 1.25rem;
}

.tw-gap-56:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  gap: 14rem;
}

.tw-gap-6:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  gap: 1.5rem;
}

.tw-gap-64:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  gap: 16rem;
}

.tw-gap-8:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  gap: 2rem;
}

.tw-gap-x-0\.5:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  column-gap: .125rem;
}

.tw-gap-x-1:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  column-gap: .25rem;
}

.tw-gap-x-2:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  column-gap: .5rem;
}

.tw-gap-x-4:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  column-gap: 1rem;
}

.tw-gap-x-8:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  column-gap: 2rem;
}

.tw-gap-y-2:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  row-gap: .5rem;
}

.tw-gap-y-28:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  row-gap: 7rem;
}

.tw-gap-y-3:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  row-gap: .75rem;
}

.tw-gap-y-4:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  row-gap: 1rem;
}

.tw-space-y-2:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(.5rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(.5rem * var(--tw-space-y-reverse));
}

.tw-space-y-3:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(.75rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(.75rem * var(--tw-space-y-reverse));
}

.tw-space-y-4:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1rem * var(--tw-space-y-reverse));
}

.tw-space-y-8:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(2rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(2rem * var(--tw-space-y-reverse));
}

.tw-divide-x:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-x-reverse: 0;
  border-right-width: calc(1px * var(--tw-divide-x-reverse));
  border-left-width: calc(1px * calc(1 - var(--tw-divide-x-reverse)));
}

.tw-divide-x-0:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-x-reverse: 0;
  border-right-width: calc(0px * var(--tw-divide-x-reverse));
  border-left-width: calc(0px * calc(1 - var(--tw-divide-x-reverse)));
}

.tw-divide-y:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-y-reverse: 0;
  border-top-width: calc(1px * calc(1 - var(--tw-divide-y-reverse)));
  border-bottom-width: calc(1px * var(--tw-divide-y-reverse));
}

.tw-divide-y-0:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-y-reverse: 0;
  border-top-width: calc(0px * calc(1 - var(--tw-divide-y-reverse)));
  border-bottom-width: calc(0px * var(--tw-divide-y-reverse));
}

.tw-divide-solid:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) > :not([hidden]) ~ :not([hidden]) {
  border-style: solid;
}

.tw-divide-ink-border-subtle:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) > :not([hidden]) ~ :not([hidden]) {
  border-color: var(--color-ink-border-subtle);
}

.tw-divide-ink-brand-subtle:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) > :not([hidden]) ~ :not([hidden]) {
  border-color: var(--color-ink-brand-subtle);
}

.tw-self-start:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  align-self: flex-start;
}

.tw-self-end:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  align-self: flex-end;
}

.tw-self-center:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  align-self: center;
}

.tw-overflow-auto:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  overflow: auto;
}

.tw-overflow-hidden:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  overflow: hidden;
}

.tw-overflow-clip:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  overflow: clip;
}

.tw-overflow-visible:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  overflow: visible;
}

.tw-overflow-scroll:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  overflow: scroll;
}

.tw-overflow-x-auto:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  overflow-x: auto;
}

.tw-overflow-y-auto:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  overflow-y: auto;
}

.tw-overflow-x-hidden:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  overflow-x: hidden;
}

.tw-overflow-y-scroll:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  overflow-y: scroll;
}

.tw-scroll-smooth:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  scroll-behavior: smooth;
}

.tw-overflow-ellipsis:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#), .tw-text-ellipsis:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  text-overflow: ellipsis;
}

.tw-hyphens-auto:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  -webkit-hyphens: auto;
  hyphens: auto;
}

.tw-whitespace-normal:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  white-space: normal;
}

.tw-whitespace-nowrap:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  white-space: nowrap;
}

.tw-whitespace-pre:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  white-space: pre;
}

.tw-text-wrap:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  text-wrap: wrap;
}

.tw-text-nowrap:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  text-wrap: nowrap;
}

.tw-text-balance:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  text-wrap: balance;
}

.tw-text-pretty:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  text-wrap: pretty;
}

.tw-break-words:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  overflow-wrap: break-word;
}

.tw-rounded:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  border-radius: .25rem;
}

.tw-rounded-\[50\%\]:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  border-radius: 50%;
}

.tw-rounded-button:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  border-radius: var(--button-border-radius);
}

.tw-rounded-card-lg:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  border-radius: var(--card-border-radius-lg);
}

.tw-rounded-full:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  border-radius: 9999px;
}

.tw-rounded-lg:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  border-radius: .5rem;
}

.tw-rounded-md:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  border-radius: .375rem;
}

.tw-rounded-none:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  border-radius: 0;
}

.tw-rounded-b:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  border-bottom-right-radius: .25rem;
  border-bottom-left-radius: .25rem;
}

.tw-rounded-b-lg:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  border-bottom-right-radius: .5rem;
  border-bottom-left-radius: .5rem;
}

.tw-rounded-b-none:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.tw-rounded-l-none:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.tw-rounded-r-none:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.tw-rounded-t-lg:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  border-top-left-radius: .5rem;
  border-top-right-radius: .5rem;
}

.tw-rounded-t-sm:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  border-top-left-radius: .125rem;
  border-top-right-radius: .125rem;
}

.tw-rounded-bl-none:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  border-bottom-left-radius: 0;
}

.tw-rounded-br-none:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  border-bottom-right-radius: 0;
}

.tw-rounded-tl-none:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  border-top-left-radius: 0;
}

.tw-rounded-tr-none:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  border-top-right-radius: 0;
}

.tw-border:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  border-width: 1px;
}

.tw-border-0:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  border-width: 0;
}

.tw-border-2:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  border-width: 2px;
}

.tw-border-x:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  border-left-width: 1px;
  border-right-width: 1px;
}

.tw-border-x-0:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  border-left-width: 0;
  border-right-width: 0;
}

.tw-border-y-0:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  border-top-width: 0;
  border-bottom-width: 0;
}

.tw-border-b:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  border-bottom-width: 1px;
}

.tw-border-b-0:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  border-bottom-width: 0;
}

.tw-border-b-2:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  border-bottom-width: 2px;
}

.tw-border-b-\[1\.5px\]:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  border-bottom-width: 1.5px;
}

.tw-border-b-\[1px\]:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  border-bottom-width: 1px;
}

.tw-border-l:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  border-left-width: 1px;
}

.tw-border-l-2:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  border-left-width: 2px;
}

.tw-border-r:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  border-right-width: 1px;
}

.tw-border-t:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  border-top-width: 1px;
}

.tw-border-t-0:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  border-top-width: 0;
}

.tw-border-t-\[1px\]:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  border-top-width: 1px;
}

.tw-border-solid:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  border-style: solid;
}

.tw-border-none:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  border-style: none;
}

.\!tw-border-button-primary-bg {
  border-color: var(--color-button-primary-bg) !important;
}

.\!tw-border-calendar-cancelled-border {
  border-color: var(--color-calendar-cancelled-border) !important;
}

.\!tw-border-calendar-paused-border {
  border-color: var(--color-calendar-paused-text) !important;
}

.\!tw-border-calendar-selected-bg {
  border-color: var(--color-calendar-selected-bg) !important;
}

.\!tw-border-error-600 {
  border-color: var(--color-error-600) !important;
}

.\!tw-border-ink-error {
  border-color: var(--color-ink-error) !important;
}

.\!tw-border-transparent {
  border-color: #0000 !important;
}

.tw-border-base-200:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  border-color: var(--color-base-200);
}

.tw-border-base-300:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  border-color: var(--color-base-300);
}

.tw-border-base-400:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  border-color: var(--color-base-400);
}

.tw-border-base-transparent:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  border-color: var(--color-base-transparent);
}

.tw-border-button-danger-bg:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  border-color: var(--color-button-danger-bg);
}

.tw-border-button-primary-bg:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  border-color: var(--color-button-primary-bg);
}

.tw-border-button-secondary-border:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  border-color: var(--color-button-secondary-border);
}

.tw-border-calendar-cancelled-bg:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  border-color: var(--color-calendar-cancelled-bg);
}

.tw-border-calendar-cancelled-border:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  border-color: var(--color-calendar-cancelled-border);
}

.tw-border-calendar-paused-bg:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  border-color: var(--color-calendar-paused-bg);
}

.tw-border-calendar-paused-text:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  border-color: var(--color-calendar-paused-text);
}

.tw-border-calendar-selected-bg:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  border-color: var(--color-calendar-selected-bg);
}

.tw-border-error-600:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  border-color: var(--color-error-600);
}

.tw-border-ink-border-default:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  border-color: var(--color-ink-border-default);
}

.tw-border-ink-border-subtle:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  border-color: var(--color-ink-border-subtle);
}

.tw-border-ink-brand-default:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  border-color: var(--color-ink-brand-default);
}

.tw-border-ink-brand-subtle:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  border-color: var(--color-ink-brand-subtle);
}

.tw-border-ink-disabled:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  border-color: var(--color-ink-disabled);
}

.tw-border-ink-focus-inverted:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  border-color: var(--color-ink-focus-inverted);
}

.tw-border-success-600:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  border-color: var(--color-success-600);
}

.tw-border-surface-action:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  border-color: var(--color-surface-action);
}

.tw-border-surface-default:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  border-color: var(--color-surface-default);
}

.tw-border-transparent:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  border-color: #0000;
}

.tw-border-b-ink-border-subtle:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  border-bottom-color: var(--color-ink-border-subtle);
}

.tw-border-l-error-600:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  border-left-color: var(--color-error-600);
}

.tw-border-l-ink-border-subtle:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  border-left-color: var(--color-ink-border-subtle);
}

.tw-border-r-ink-border-subtle:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  border-right-color: var(--color-ink-border-subtle);
}

.tw-border-t-ink-border-subtle:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  border-top-color: var(--color-ink-border-subtle);
}

.tw-border-t-ink-brand-subtle:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  border-top-color: var(--color-ink-brand-subtle);
}

.\!tw-bg-base-50 {
  background-color: var(--color-base-50) !important;
}

.\!tw-bg-button-primary-bg {
  background-color: var(--color-button-primary-bg) !important;
}

.\!tw-bg-button-tertiary-bg-hover {
  background-color: var(--color-button-tertiary-bg-hover) !important;
}

.\!tw-bg-calendar-cancelled-bg {
  background-color: var(--color-calendar-cancelled-bg) !important;
}

.\!tw-bg-calendar-holiday-bg-hover {
  background-color: var(--color-button-secondary-bg-hover) !important;
}

.\!tw-bg-calendar-paused-bg {
  background-color: var(--color-calendar-paused-bg) !important;
}

.\!tw-bg-calendar-paused-bg-hover {
  background-color: var(--color-calendar-paused-bg-hover) !important;
}

.\!tw-bg-calendar-selected-bg {
  background-color: var(--color-calendar-selected-bg) !important;
}

.\!tw-bg-surface-error {
  background-color: var(--color-surface-error) !important;
}

.\!tw-bg-transparent {
  background-color: #0000 !important;
}

.tw-bg-\[--color-base-0\]:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  background-color: var(--color-base-0);
}

.tw-bg-\[--color-body-bg\]:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  background-color: var(--color-body-bg);
}

.tw-bg-background-default-inverted:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  background-color: var(--color-background-default-inverted);
}

.tw-bg-background-subtle:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  background-color: var(--color-background-subtle);
}

.tw-bg-base-0:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  background-color: var(--color-base-0);
}

.tw-bg-base-100:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  background-color: var(--color-base-100);
}

.tw-bg-base-200:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  background-color: var(--color-base-200);
}

.tw-bg-base-300:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  background-color: var(--color-base-300);
}

.tw-bg-base-400:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  background-color: var(--color-base-400);
}

.tw-bg-base-50:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  background-color: var(--color-base-50);
}

.tw-bg-base-900:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  background-color: var(--color-base-900);
}

.tw-bg-base-transparent:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  background-color: var(--color-base-transparent);
}

.tw-bg-button-danger-bg:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  background-color: var(--color-button-danger-bg);
}

.tw-bg-button-disabled-bg:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  background-color: var(--color-button-disabled-bg);
}

.tw-bg-button-primary-bg:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  background-color: var(--color-button-primary-bg);
}

.tw-bg-button-secondary-bg:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  background-color: var(--color-button-secondary-bg);
}

.tw-bg-button-tertiary-bg:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  background-color: var(--color-button-tertiary-bg);
}

.tw-bg-button-tertiary-bg-hover:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  background-color: var(--color-button-tertiary-bg-hover);
}

.tw-bg-calendar-cancelled-bg:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  background-color: var(--color-calendar-cancelled-bg);
}

.tw-bg-calendar-extra-bg:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  background-color: var(--color-calendar-extra-bg);
}

.tw-bg-calendar-paused-bg:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  background-color: var(--color-calendar-paused-bg);
}

.tw-bg-calendar-selectable-bg:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  background-color: var(--color-calendar-selectable-bg);
}

.tw-bg-calendar-selected-bg:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  background-color: var(--color-calendar-selected-bg);
}

.tw-bg-chip-active:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  background-color: var(--color-chip-active);
}

.tw-bg-chip-default:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  background-color: var(--color-chip-default);
}

.tw-bg-christmas:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  background-color: var(--color-christmas);
}

.tw-bg-error-100:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  background-color: var(--color-error-100);
}

.tw-bg-headerFooterBackground:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  background-color: var(--color-header-footer-bg);
}

.tw-bg-infoBoxBackground:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  background-color: var(--color-info-box-bg);
}

.tw-bg-information-100:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  background-color: var(--color-information-100);
}

.tw-bg-ink-brand-subtle:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  background-color: var(--color-ink-brand-subtle);
}

.tw-bg-notification-bg:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  background-color: var(--notification-marker-surface);
}

.tw-bg-promotion:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  background-color: var(--color-promotion);
}

.tw-bg-success-100:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  background-color: var(--color-success-100);
}

.tw-bg-surface-action:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  background-color: var(--color-surface-action);
}

.tw-bg-surface-default:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  background-color: var(--color-surface-default);
}

.tw-bg-surface-default-hover:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  background-color: var(--color-surface-default-hover);
}

.tw-bg-surface-default-inverted:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  background-color: var(--color-surface-default-inverted);
}

.tw-bg-surface-error:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  background-color: var(--color-surface-error);
}

.tw-bg-surface-information:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  background-color: var(--color-surface-information);
}

.tw-bg-surface-success:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  background-color: var(--color-surface-success);
}

.tw-bg-surface-tip:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  background-color: var(--color-surface-tip);
}

.tw-bg-surface-warning:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  background-color: var(--color-surface-warning);
}

.tw-bg-transparent:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  background-color: #0000;
}

.tw-bg-warning-100:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  background-color: var(--color-warning-100);
}

.tw-bg-gradient-to-b:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  background-image: linear-gradient(to bottom, var(--tw-gradient-stops));
}

.tw-bg-gradient-to-t:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  background-image: linear-gradient(to top, var(--tw-gradient-stops));
}

.tw-from-\[\#0000\]:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  --tw-gradient-from: #0000 var(--tw-gradient-from-position);
  --tw-gradient-to: #0000 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.tw-from-surface-default:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  --tw-gradient-from: var(--color-surface-default) var(--tw-gradient-from-position);
  --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.tw-from-55\%:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  --tw-gradient-from-position: 55%;
}

.tw-from-65\%:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  --tw-gradient-from-position: 65%;
}

.tw-to-\[\#232323ab\]:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  --tw-gradient-to: #232323ab var(--tw-gradient-to-position);
}

.tw-to-100\%:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  --tw-gradient-to-position: 100%;
}

.tw-bg-cover:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  background-size: cover;
}

.tw-bg-\[center_center\]:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#), .tw-bg-center:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  background-position: center;
}

.\!tw-fill-base-0 {
  fill: var(--color-base-0) !important;
}

.\!tw-fill-ink-default {
  fill: var(--color-ink-default) !important;
}

.\!tw-fill-ink-error {
  fill: var(--color-ink-error) !important;
}

.tw-fill-base-300:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  fill: var(--color-base-300);
}

.tw-fill-button-primary-text:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  fill: var(--color-button-primary-text);
}

.tw-fill-button-secondary-text:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  fill: var(--color-button-secondary-text);
}

.tw-fill-error-600:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  fill: var(--color-error-600);
}

.tw-fill-favorite:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  fill: var(--color-ink-favorite);
}

.tw-fill-information-600:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  fill: var(--color-information-600);
}

.tw-fill-ink-brand-default:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  fill: var(--color-ink-brand-default);
}

.tw-fill-ink-brand-inverted:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  fill: var(--color-ink-brand-inverted);
}

.tw-fill-ink-disabled:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  fill: var(--color-ink-disabled);
}

.tw-fill-link-default:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  fill: var(--color-link-default);
}

.tw-fill-link-inverted:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  fill: var(--color-link-default-inverted);
}

.tw-fill-success-600:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  fill: var(--color-success-600);
}

.tw-fill-surface-action:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  fill: var(--color-surface-action);
}

.tw-fill-surface-default:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  fill: var(--color-surface-default);
}

.tw-fill-warning-600:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  fill: var(--color-warning-600);
}

.tw-stroke-ink-error:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  stroke: var(--color-ink-error);
}

.tw-object-cover:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  object-fit: cover;
}

.\!tw-p-1 {
  padding: .25rem !important;
}

.tw-p-0:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  padding: 0;
}

.tw-p-1:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  padding: .25rem;
}

.tw-p-12:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  padding: 3rem;
}

.tw-p-2:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  padding: .5rem;
}

.tw-p-3:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  padding: .75rem;
}

.tw-p-4:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  padding: 1rem;
}

.tw-p-5:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  padding: 1.25rem;
}

.tw-p-6:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  padding: 1.5rem;
}

.tw-p-8:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  padding: 2rem;
}

.tw-px-0:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  padding-left: 0;
  padding-right: 0;
}

.tw-px-1:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  padding-left: .25rem;
  padding-right: .25rem;
}

.tw-px-10:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  padding-left: 2.5rem;
  padding-right: 2.5rem;
}

.tw-px-12:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  padding-left: 3rem;
  padding-right: 3rem;
}

.tw-px-2:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  padding-left: .5rem;
  padding-right: .5rem;
}

.tw-px-3:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  padding-left: .75rem;
  padding-right: .75rem;
}

.tw-px-4:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  padding-left: 1rem;
  padding-right: 1rem;
}

.tw-px-5:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.tw-px-6:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.tw-px-8:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  padding-left: 2rem;
  padding-right: 2rem;
}

.tw-px-button-compact-padding-x:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  padding-left: var(--button-compact-padding-x);
  padding-right: var(--button-compact-padding-x);
}

.tw-px-button-regular-padding-x:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  padding-left: var(--button-regular-padding-x);
  padding-right: var(--button-regular-padding-x);
}

.tw-py-0:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  padding-top: 0;
  padding-bottom: 0;
}

.tw-py-0\.5:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  padding-top: .125rem;
  padding-bottom: .125rem;
}

.tw-py-1:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  padding-top: .25rem;
  padding-bottom: .25rem;
}

.tw-py-10:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}

.tw-py-12:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.tw-py-2:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.tw-py-3:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  padding-top: .75rem;
  padding-bottom: .75rem;
}

.tw-py-4:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.tw-py-5:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}

.tw-py-6:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.tw-py-7:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  padding-top: 1.75rem;
  padding-bottom: 1.75rem;
}

.tw-py-8:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.tw-py-\[--topnav-categories-padding-y\]:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  padding-top: var(--topnav-categories-padding-y);
  padding-bottom: var(--topnav-categories-padding-y);
}

.tw-py-\[--topnav-padding-y\]:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  padding-top: var(--topnav-padding-y);
  padding-bottom: var(--topnav-padding-y);
}

.tw-py-button-compact-padding-y:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  padding-top: var(--button-compact-padding-y);
  padding-bottom: var(--button-compact-padding-y);
}

.tw-py-button-regular-padding-y:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  padding-top: var(--button-regular-padding-y);
  padding-bottom: var(--button-regular-padding-y);
}

.tw-pb-1:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  padding-bottom: .25rem;
}

.tw-pb-12:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  padding-bottom: 3rem;
}

.tw-pb-16:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  padding-bottom: 4rem;
}

.tw-pb-2:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  padding-bottom: .5rem;
}

.tw-pb-20:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  padding-bottom: 5rem;
}

.tw-pb-4:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  padding-bottom: 1rem;
}

.tw-pb-5:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  padding-bottom: 1.25rem;
}

.tw-pb-6:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  padding-bottom: 1.5rem;
}

.tw-pb-8:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  padding-bottom: 2rem;
}

.tw-pl-0:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  padding-left: 0;
}

.tw-pl-1:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  padding-left: .25rem;
}

.tw-pl-10:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  padding-left: 2.5rem;
}

.tw-pl-2:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  padding-left: .5rem;
}

.tw-pl-3:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  padding-left: .75rem;
}

.tw-pl-4:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  padding-left: 1rem;
}

.tw-pl-6:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  padding-left: 1.5rem;
}

.tw-pl-8:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  padding-left: 2rem;
}

.tw-pr-1:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  padding-right: .25rem;
}

.tw-pr-16:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  padding-right: 4rem;
}

.tw-pr-2:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  padding-right: .5rem;
}

.tw-pr-4:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  padding-right: 1rem;
}

.tw-pr-8:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  padding-right: 2rem;
}

.tw-ps-0:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  padding-inline-start: 0;
}

.tw-pt-1:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  padding-top: .25rem;
}

.tw-pt-12:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  padding-top: 3rem;
}

.tw-pt-16:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  padding-top: 4rem;
}

.tw-pt-2:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  padding-top: .5rem;
}

.tw-pt-3:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  padding-top: .75rem;
}

.tw-pt-4:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  padding-top: 1rem;
}

.tw-pt-6:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  padding-top: 1.5rem;
}

.tw-pt-8:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  padding-top: 2rem;
}

.tw-text-left:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  text-align: left;
}

.tw-text-center:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  text-align: center;
}

.tw-text-right:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  text-align: right;
}

.tw-text-start:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  text-align: start;
}

.tw-text-end:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  text-align: end;
}

.tw-align-top:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  vertical-align: top;
}

.tw-align-middle:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  vertical-align: middle;
}

.tw-font-sans:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  font-family: ui-sans-serif, system-ui, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
}

.tw-font-suomi:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  font-family: suomi, sans-serif;
}

.tw-text-2xl:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  font-size: 1.5rem;
  line-height: 2rem;
}

.tw-text-3xl:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  font-size: 1.875rem;
  line-height: 2.25rem;
}

.tw-text-4xl:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  font-size: 2.5625rem;
  line-height: 3rem;
}

.tw-text-6xl:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  font-size: 3.75rem;
  line-height: 1;
}

.tw-text-\[0\.67em\]:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  font-size: .67em;
}

.tw-text-\[14px\]:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  font-size: 14px;
}

.tw-text-base:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  font-size: 1rem;
  line-height: 1.5rem;
}

.tw-text-lg:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#), .tw-text-lg\/7:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.tw-text-sm:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  font-size: .875rem;
  line-height: 1.25rem;
}

.tw-text-sm\/\[16px\]:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  font-size: .875rem;
  line-height: 16px;
}

.tw-text-xl:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.tw-text-xs:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  font-size: .75rem;
  line-height: 1rem;
}

.tw-text-xxs:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  font-size: 11px;
  line-height: 13px;
}

.tw-font-bold:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  font-weight: 700;
}

.tw-font-medium:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  font-weight: 500;
}

.tw-font-normal:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  font-weight: 400;
}

.tw-font-semibold:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  font-weight: 600;
}

.tw-font-thin:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  font-weight: 100;
}

.tw-uppercase:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  text-transform: uppercase;
}

.tw-ordinal:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  --tw-ordinal: ordinal;
  font-variant-numeric: var(--tw-ordinal) var(--tw-slashed-zero) var(--tw-numeric-figure) var(--tw-numeric-spacing) var(--tw-numeric-fraction);
}

.tw-leading-4:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  line-height: 1rem;
}

.tw-leading-5:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  line-height: 1.25rem;
}

.tw-leading-8:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  line-height: 2rem;
}

.tw-leading-none:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  line-height: 1;
}

.tw-leading-normal:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  line-height: 1.5;
}

.tw-leading-relaxed:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  line-height: 1.625;
}

.tw-leading-tight:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  line-height: 1.25;
}

.tw-tracking-normal:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  letter-spacing: 0;
}

.\!tw-text-calendar-cancelled-text {
  color: var(--color-calendar-cancelled-text) !important;
}

.\!tw-text-calendar-holiday-text {
  color: var(--color-calendar-holiday-text) !important;
}

.\!tw-text-calendar-paused-text {
  color: var(--color-calendar-paused-text) !important;
}

.\!tw-text-calendar-selected-text {
  color: var(--color-calendar-selected-text) !important;
}

.\!tw-text-headerFooterText {
  color: var(--color-header-footer-text) !important;
}

.\!tw-text-ink-default {
  color: var(--color-ink-default) !important;
}

.\!tw-text-ink-disabled {
  color: var(--color-ink-disabled) !important;
}

.tw-text-\[--dsp-color-ink-favorites\]:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  color: var(--dsp-color-ink-favorites);
}

.tw-text-base-0:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  color: var(--color-base-0);
}

.tw-text-base-400:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  color: var(--color-base-400);
}

.tw-text-base-600:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  color: var(--color-base-600);
}

.tw-text-base-900:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  color: var(--color-base-900);
}

.tw-text-button-primary-text:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  color: var(--color-button-primary-text);
}

.tw-text-button-secondary-text:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  color: var(--color-button-secondary-text);
}

.tw-text-button-tertiary-bg-hover:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  color: var(--color-button-tertiary-bg-hover);
}

.tw-text-button-tertiary-text:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  color: var(--color-button-tertiary-text);
}

.tw-text-calendar-cancelled-text:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  color: var(--color-calendar-cancelled-text);
}

.tw-text-calendar-paused-text:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  color: var(--color-calendar-paused-text);
}

.tw-text-calendar-selected-text:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  color: var(--color-calendar-selected-text);
}

.tw-text-error-600:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  color: var(--color-error-600);
}

.tw-text-headerFooterText:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  color: var(--color-header-footer-text);
}

.tw-text-ink-border-subtle:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  color: var(--color-ink-border-subtle);
}

.tw-text-ink-brand-default:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  color: var(--color-ink-brand-default);
}

.tw-text-ink-brand-inverted:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  color: var(--color-ink-brand-inverted);
}

.tw-text-ink-brand-subtle:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  color: var(--color-ink-brand-subtle);
}

.tw-text-ink-default:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  color: var(--color-ink-default);
}

.tw-text-ink-disabled:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  color: var(--color-ink-disabled);
}

.tw-text-ink-error:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  color: var(--color-ink-error);
}

.tw-text-ink-information:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  color: var(--color-ink-information);
}

.tw-text-ink-success:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  color: var(--color-ink-success);
}

.tw-text-ink-warning:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  color: var(--color-ink-warning);
}

.tw-text-link-default:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  color: var(--color-link-default);
}

.tw-text-link-inverted:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  color: var(--color-link-default-inverted);
}

.tw-text-muted:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  color: var(--color-ink-muted);
}

.tw-text-notification-text:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  color: var(--notification-marker-ink);
}

.tw-text-promotion:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  color: var(--color-promotion);
}

.tw-text-surface-default:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  color: var(--color-surface-default);
}

.tw-underline:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  text-decoration-line: underline;
}

.tw-line-through:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  text-decoration-line: line-through;
}

.\!tw-no-underline {
  text-decoration-line: none !important;
}

.tw-no-underline:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  text-decoration-line: none;
}

.tw-underline-offset-2:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  text-underline-offset: 2px;
}

.tw-underline-offset-4:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  text-underline-offset: 4px;
}

.tw-accent-surface-action:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  accent-color: var(--color-surface-action);
}

.tw-opacity-0:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  opacity: 0;
}

.tw-opacity-100:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  opacity: 1;
}

.tw-opacity-25:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  opacity: .25;
}

.tw-opacity-30:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  opacity: .3;
}

.tw-opacity-50:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  opacity: .5;
}

.tw-opacity-85:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  opacity: .85;
}

.tw-opacity-90:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  opacity: .9;
}

.tw-mix-blend-darken:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  mix-blend-mode: darken;
}

.tw-shadow:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  --tw-shadow: 0 1px 3px 0 #0000001a, 0 1px 2px -1px #0000001a;
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.tw-shadow-lg:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  --tw-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -4px #0000001a;
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.tw-shadow-md:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  --tw-shadow: 0 4px 6px -1px #0000001a, 0 2px 4px -2px #0000001a;
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.tw-shadow-sm:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  --tw-shadow: 0 1px 2px 0 #0000000d;
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.tw-shadow-xl:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  --tw-shadow: 0 20px 25px -5px #0000001a, 0 8px 10px -6px #0000001a;
  --tw-shadow-colored: 0 20px 25px -5px var(--tw-shadow-color), 0 8px 10px -6px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.tw-shadow-\[\#00000080\]:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  --tw-shadow-color: #00000080;
  --tw-shadow: var(--tw-shadow-colored);
}

.tw-outline-none:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.tw-outline:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  outline-style: solid;
}

.\!tw-outline-0 {
  outline-width: 0 !important;
}

.tw-outline-2:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  outline-width: 2px;
}

.tw-outline-ink-focus:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  outline-color: var(--color-ink-focus);
}

.tw-ring:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.tw-ring-0:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.tw-ring-1:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.tw-ring-2:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.tw-ring-information-600:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  --tw-ring-color: var(--color-information-600);
}

.tw-ring-surface-default:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  --tw-ring-color: var(--color-surface-default);
}

.tw-ring-offset-2:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  --tw-ring-offset-width: 2px;
}

.tw-ring-offset-button-primary-bg:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  --tw-ring-offset-color: var(--color-button-primary-bg);
}

.tw-brightness-50:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  --tw-brightness: brightness(.5);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.tw-drop-shadow-lg:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  --tw-drop-shadow: drop-shadow(0 10px 8px #0000000a) drop-shadow(0 4px 3px #0000001a);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.tw-drop-shadow-md:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  --tw-drop-shadow: drop-shadow(0 4px 3px #00000012) drop-shadow(0 2px 2px #0000000f);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.tw-filter:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.tw-transition:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.tw-transition-\[max-height\]:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  transition-property: max-height;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.tw-transition-all:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  transition-property: all;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.tw-transition-colors:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.tw-transition-opacity:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  transition-property: opacity;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.tw-transition-transform:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  transition-property: transform;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.tw-duration-200:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  transition-duration: .2s;
}

.tw-duration-300:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  transition-duration: .3s;
}

.tw-duration-500:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  transition-duration: .5s;
}

.tw-ease-\[cubic-bezier\(0\.42\,0\,0\.58\,1\)\]:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  transition-timing-function: ease-in-out;
}

.tw-ease-in:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  transition-timing-function: cubic-bezier(.4, 0, 1, 1);
}

.tw-ease-in-out:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.tw-ease-out:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  transition-timing-function: cubic-bezier(0, 0, .2, 1);
}

.tw-\@container:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  container-type: inline-size;
}

.tw-open-calendar-slot:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  transform: perspective(1200px)rotateY(-98deg);
}

.tw-opened-calendar-slot:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  transform: perspective(1200px)rotateY(-30deg);
}

.tw-hover-calendar-slot:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  transform: perspective(1200px)rotateY(-50deg);
}

.tw-text-shadow:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  text-shadow: 0 2px 4px var(--tw-shadow-color);
}

.tw-text-shadow-default:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  text-shadow: 1px 1px 3px var(--color-base-900);
}

:root:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  --main-column-max-width: 1416px;
  --article-column-max-width: 1320px;
  --article-px: calc((var(--main-column-max-width)  - var(--article-column-max-width)) / 2);
  --account-menu-width: 200px;
  --barcode-container-max-width: 640px;
  --popover-border-width: 1px;
  --popover-arrow-width: 16px;
  --popover-arrow-height: calc(var(--popover-arrow-width) * .5);
  --popover-arrow-displacement: -7px;
  --popover-bg: var(--color-surface-default);
  --topnav-padding-y: .5rem;
  --topnav-item-padding-x: .5rem;
  --topnav-categories-padding-y: .5rem;
  --topnav-megamenu-width: 95%;
  --topnav-megamenu-max-width: 100%;
  --minicart-item-breakpoint: 575px;
  --search-input-min-width: 420px;
  --search-quick-results-item-bg-hover: #00000005;
  --color-calendar-holiday-text: var(--color-ink-error);
  --color-calendar-cancelled-bg: var(--color-error-100);
  --color-calendar-cancelled-border: var(--color-error-100);
  --color-calendar-selectable-bg: var(--color-information-100);
  --color-calendar-selectable-bg-hover: #adcffd;
  --color-calendar-extra-bg: var(--color-warning-100);
  --color-calendar-extra-bg-hover: #fde996;
  --color-calendar-paused-bg: var(--color-base-0);
  --color-calendar-paused-bg-hover: #cce5ee;
  --color-calendar-paused-text: var(--color-ink-brand-default);
  --color-calendar-selected-text: var(--color-surface-default);
  --color-calendar-selected-bg: var(--color-ink-brand-default);
  --default-font-family: "Inter", "Maison Neue", Helvetica, Arial, sans-serif;
  --color-ink-favorite: var(--color-red-700);
  --color-promotion: var(--color-red-700);
  --color-body-bg: #fafafa;
  --color-christmas: var(--color-red-700);
  --color-info-box-bg: var(--color-cyan-50);
  --color-ink-muted: var(--color-base-500);
  --color-header-footer-bg: var(--color-surface-default-inverted);
  --color-header-footer-text: var(--color-ink-brand-inverted);
  --notification-marker-surface: var(--color-red-700);
  --notification-marker-ink: var(--color-ink-brand-inverted);
}

:root:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) input:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#)::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

:root:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) input:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#)::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

:root:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) input[type="number"]:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  -webkit-appearance: textfield;
  appearance: textfield;
}

.\[tw-transition\:width_0\.5s\]:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  tw-transition: width .5s;
}

.dsp-confirmation-banner:not(#\#):not(#\#) {
  background-image: -webkit-image-set(url("order_confirmation.4fac572a.webp") 1.01x, url("order_confirmation.3f081207.jpeg") 1.01x);
  background-image: image-set("order_confirmation.4fac572a.webp" 1.01x, "order_confirmation.3f081207.jpeg" 1.01x);
}

@font-face {
  font-family: suomi;
  src: url("Suomi-Hand.3e04979b.otf");
  font-display: swap;
}

.\*\:tw-fill-link-inverted:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) > * {
  fill: var(--color-link-default-inverted);
}

.file\:tw-mr-5:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#)::file-selector-button {
  margin-right: 1.25rem;
}

.file\:tw-rounded-button:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#)::file-selector-button {
  border-radius: var(--button-border-radius);
}

.file\:tw-border-solid:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#)::file-selector-button {
  border-style: solid;
}

.file\:tw-border-button-primary-bg:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#)::file-selector-button {
  border-color: var(--color-button-primary-bg);
}

.file\:tw-bg-button-primary-bg:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#)::file-selector-button {
  background-color: var(--color-button-primary-bg);
}

.file\:tw-px-button-regular-padding-x:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#)::file-selector-button {
  padding-left: var(--button-regular-padding-x);
  padding-right: var(--button-regular-padding-x);
}

.file\:tw-py-button-regular-padding-y:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#)::file-selector-button {
  padding-top: var(--button-regular-padding-y);
  padding-bottom: var(--button-regular-padding-y);
}

.file\:tw-text-button-primary-text:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#)::file-selector-button {
  color: var(--color-button-primary-text);
}

.placeholder\:tw-text-ink-disabled:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#)::placeholder {
  color: var(--color-ink-disabled);
}

.before\:tw-absolute:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):before {
  content: var(--tw-content);
  position: absolute;
}

.before\:tw-inset-0:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):before {
  content: var(--tw-content);
  inset: 0;
}

.before\:tw-bottom-0:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):before {
  content: var(--tw-content);
  bottom: 0;
}

.before\:tw-left-0:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):before {
  content: var(--tw-content);
  left: 0;
}

.before\:tw-right-0:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):before {
  content: var(--tw-content);
  right: 0;
}

.before\:tw-top-0:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):before {
  content: var(--tw-content);
  top: 0;
}

.before\:tw-z-0:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):before {
  content: var(--tw-content);
  z-index: 0;
}

.before\:tw-border-x-\[line-width\:--popover-arrow-height\]:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):before {
  content: var(--tw-content);
  border-left-width: var(--popover-arrow-height);
  border-right-width: var(--popover-arrow-height);
}

.before\:tw-border-y-\[line-width\:--popover-arrow-height\]:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):before {
  content: var(--tw-content);
  border-top-width: var(--popover-arrow-height);
  border-bottom-width: var(--popover-arrow-height);
}

.before\:tw-border-b-\[line-width\:--popover-arrow-height\]:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):before {
  content: var(--tw-content);
  border-bottom-width: var(--popover-arrow-height);
}

.before\:tw-border-l-\[line-width\:--popover-arrow-height\]:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):before {
  content: var(--tw-content);
  border-left-width: var(--popover-arrow-height);
}

.before\:tw-border-r-\[line-width\:--popover-arrow-height\]:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):before {
  content: var(--tw-content);
  border-right-width: var(--popover-arrow-height);
}

.before\:tw-border-t-\[line-width\:--popover-arrow-height\]:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):before {
  content: var(--tw-content);
  border-top-width: var(--popover-arrow-height);
}

.before\:tw-border-solid:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):before {
  content: var(--tw-content);
  border-style: solid;
}

.before\:tw-border-ink-border-subtle:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):before {
  content: var(--tw-content);
  border-color: var(--color-ink-border-subtle);
}

.before\:tw-border-x-transparent:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):before {
  content: var(--tw-content);
  border-left-color: #0000;
  border-right-color: #0000;
}

.before\:tw-border-y-transparent:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):before {
  content: var(--tw-content);
  border-top-color: #0000;
  border-bottom-color: #0000;
}

.before\:tw-border-b-transparent:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):before {
  content: var(--tw-content);
  border-bottom-color: #0000;
}

.before\:tw-border-l-transparent:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):before {
  content: var(--tw-content);
  border-left-color: #0000;
}

.before\:tw-border-r-transparent:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):before {
  content: var(--tw-content);
  border-right-color: #0000;
}

.before\:tw-border-t-transparent:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):before {
  content: var(--tw-content);
  border-top-color: #0000;
}

.before\:tw-content-\[\'\'\]:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):before {
  --tw-content: "";
  content: var(--tw-content);
}

.after\:tw-absolute:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):after {
  content: var(--tw-content);
  position: absolute;
}

.after\:tw-bottom-\[--popover-border-width\]:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):after {
  content: var(--tw-content);
  bottom: var(--popover-border-width);
}

.after\:tw-left-\[--popover-border-width\]:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):after {
  content: var(--tw-content);
  left: var(--popover-border-width);
}

.after\:tw-right-\[--popover-border-width\]:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):after {
  content: var(--tw-content);
  right: var(--popover-border-width);
}

.after\:tw-top-\[--popover-border-width\]:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):after {
  content: var(--tw-content);
  top: var(--popover-border-width);
}

.after\:tw-border-x-\[line-width\:--popover-arrow-height\]:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):after {
  content: var(--tw-content);
  border-left-width: var(--popover-arrow-height);
  border-right-width: var(--popover-arrow-height);
}

.after\:tw-border-y-\[line-width\:--popover-arrow-height\]:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):after {
  content: var(--tw-content);
  border-top-width: var(--popover-arrow-height);
  border-bottom-width: var(--popover-arrow-height);
}

.after\:tw-border-b-\[line-width\:--popover-arrow-height\]:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):after {
  content: var(--tw-content);
  border-bottom-width: var(--popover-arrow-height);
}

.after\:tw-border-l-\[line-width\:--popover-arrow-height\]:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):after {
  content: var(--tw-content);
  border-left-width: var(--popover-arrow-height);
}

.after\:tw-border-r-\[line-width\:--popover-arrow-height\]:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):after {
  content: var(--tw-content);
  border-right-width: var(--popover-arrow-height);
}

.after\:tw-border-t-\[line-width\:--popover-arrow-height\]:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):after {
  content: var(--tw-content);
  border-top-width: var(--popover-arrow-height);
}

.after\:tw-border-solid:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):after {
  content: var(--tw-content);
  border-style: solid;
}

.after\:tw-border-\[--popover-bg\]:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):after {
  content: var(--tw-content);
  border-color: var(--popover-bg);
}

.after\:tw-border-x-transparent:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):after {
  content: var(--tw-content);
  border-left-color: #0000;
  border-right-color: #0000;
}

.after\:tw-border-y-transparent:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):after {
  content: var(--tw-content);
  border-top-color: #0000;
  border-bottom-color: #0000;
}

.after\:tw-border-b-transparent:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):after {
  content: var(--tw-content);
  border-bottom-color: #0000;
}

.after\:tw-border-l-transparent:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):after {
  content: var(--tw-content);
  border-left-color: #0000;
}

.after\:tw-border-r-transparent:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):after {
  content: var(--tw-content);
  border-right-color: #0000;
}

.after\:tw-border-t-transparent:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):after {
  content: var(--tw-content);
  border-top-color: #0000;
}

.after\:tw-content-\[\'\'\]:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):after {
  --tw-content: "";
  content: var(--tw-content);
}

.first\:tw-mt-0:first-child:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  margin-top: 0;
}

.first\:tw-pt-0:first-child:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  padding-top: 0;
}

.last\:tw-mb-0:last-child:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  margin-bottom: 0;
}

.last\:tw-border-b-0:last-child:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  border-bottom-width: 0;
}

.last\:tw-pb-0:last-child:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  padding-bottom: 0;
}

.odd\:tw-border-b-0:nth-child(odd):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#), .even\:tw-border-b-0:nth-child(2n):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  border-bottom-width: 0;
}

.last-of-type\:tw-border-b:last-of-type:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  border-bottom-width: 1px;
}

.last-of-type\:tw-border-b-ink-brand-subtle:last-of-type:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  border-bottom-color: var(--color-ink-brand-subtle);
}

.checked\:tw-border-base-200:checked:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  border-color: var(--color-base-200);
}

.checked\:tw-border-surface-action:checked:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  border-color: var(--color-surface-action);
}

.checked\:tw-border-surface-default:checked:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  border-color: var(--color-surface-default);
}

.checked\:tw-bg-base-0:checked:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  background-color: var(--color-base-0);
}

.checked\:tw-bg-base-50:checked:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  background-color: var(--color-base-50);
}

.checked\:tw-bg-surface-action:checked:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  background-color: var(--color-surface-action);
}

.hover\:-tw-translate-y-1:hover:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  --tw-translate-y: -.25rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.hover\:tw-scale-110:hover:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  --tw-scale-x: 1.1;
  --tw-scale-y: 1.1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.hover\:tw-cursor-pointer:hover:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  cursor: pointer;
}

.hover\:tw-border-0:hover:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  border-width: 0;
}

.hover\:tw-border-button-primary-bg-hover:hover:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  border-color: var(--color-button-primary-bg-hover);
}

.hover\:tw-border-button-secondary-border:hover:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  border-color: var(--color-button-secondary-border);
}

.hover\:tw-border-button-tertiary-bg-hover:hover:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  border-color: var(--color-button-tertiary-bg-hover);
}

.hover\:tw-border-calendar-extra-bg-hover:hover:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  border-color: var(--color-calendar-extra-bg-hover);
}

.hover\:tw-border-calendar-selectable-bg-hover:hover:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  border-color: var(--color-calendar-selectable-bg-hover);
}

.hover\:tw-border-error-800:hover:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  border-color: var(--color-error-800);
}

.hover\:tw-border-ink-brand-default:hover:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  border-color: var(--color-ink-brand-default);
}

.hover\:\!tw-bg-calendar-holiday-bg-hover:hover {
  background-color: var(--color-button-secondary-bg-hover) !important;
}

.hover\:\!tw-bg-calendar-paused-bg-hover:hover {
  background-color: var(--color-calendar-paused-bg-hover) !important;
}

.hover\:tw-bg-\[--search-quick-results-item-bg-hover\]:hover:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  background-color: var(--search-quick-results-item-bg-hover);
}

.hover\:tw-bg-base-600:hover:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  background-color: var(--color-base-600);
}

.hover\:tw-bg-button-disabled-bg:hover:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  background-color: var(--color-button-disabled-bg);
}

.hover\:tw-bg-button-primary-bg-hover:hover:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  background-color: var(--color-button-primary-bg-hover);
}

.hover\:tw-bg-button-secondary-bg-hover:hover:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  background-color: var(--color-button-secondary-bg-hover);
}

.hover\:tw-bg-button-tertiary-bg-hover:hover:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  background-color: var(--color-button-tertiary-bg-hover);
}

.hover\:tw-bg-calendar-extra-bg-hover:hover:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  background-color: var(--color-calendar-extra-bg-hover);
}

.hover\:tw-bg-calendar-selectable-bg-hover:hover:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  background-color: var(--color-calendar-selectable-bg-hover);
}

.hover\:tw-bg-chip-active-hover:hover:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  background-color: var(--color-chip-active-hover);
}

.hover\:tw-bg-chip-default-hover:hover:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  background-color: var(--color-chip-default-hover);
}

.hover\:tw-bg-error-800:hover:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  background-color: var(--color-error-800);
}

.hover\:tw-bg-infoBoxBackground:hover:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  background-color: var(--color-info-box-bg);
}

.hover\:tw-bg-surface-default-hover:hover:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  background-color: var(--color-surface-default-hover);
}

.hover\:tw-from-\[\#00000024\]:hover:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  --tw-gradient-from: #00000024 var(--tw-gradient-from-position);
  --tw-gradient-to: #0000 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.hover\:tw-to-\[\#232323ab\]:hover:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  --tw-gradient-to: #232323ab var(--tw-gradient-to-position);
}

.hover\:tw-fill-favorite:hover:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  fill: var(--color-ink-favorite);
}

.hover\:tw-stroke-ink-error:hover:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  stroke: var(--color-ink-error);
}

.hover\:tw-text-ink-brand-default:hover:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  color: var(--color-ink-brand-default);
}

.hover\:\!tw-underline:hover {
  text-decoration-line: underline !important;
}

.hover\:tw-underline:hover:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  text-decoration-line: underline;
}

.hover\:\!tw-no-underline:hover {
  text-decoration-line: none !important;
}

.hover\:tw-no-underline:hover:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  text-decoration-line: none;
}

.hover\:tw-opacity-0:hover:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  opacity: 0;
}

.hover\:tw-shadow-md:hover:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  --tw-shadow: 0 4px 6px -1px #0000001a, 0 2px 4px -2px #0000001a;
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.hover\:tw-brightness-200:hover:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  --tw-brightness: brightness(2);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.hover\:tw-filter:hover:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.\*\:hover\:tw-fill-link-inverted-hover:hover:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) > * {
  fill: var(--color-link-default-inverted-hover);
}

.hover\:file\:tw-cursor-pointer:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#)::file-selector-button:hover {
  cursor: pointer;
}

.focus\:tw-outline-none:focus:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.focus\:tw-ring-2:focus:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.focus\:tw-ring-information-600:focus:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  --tw-ring-color: var(--color-information-600);
}

.focus-visible\:tw-border-ink-brand-default:focus-visible:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  border-color: var(--color-ink-brand-default);
}

.focus-visible\:tw-border-link-default:focus-visible:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  border-color: var(--color-link-default);
}

.focus-visible\:tw-bg-base-0:focus-visible:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  background-color: var(--color-base-0);
}

.focus-visible\:tw-outline-none:focus-visible:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.focus-visible\:tw-ring:focus-visible:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.focus-visible\:tw-ring-2:focus-visible:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.focus-visible\:tw-ring-information-600:focus-visible:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  --tw-ring-color: var(--color-information-600);
}

.focus-visible\:tw-ring-offset-1:focus-visible:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  --tw-ring-offset-width: 1px;
}

.focus-visible\:tw-ring-offset-ink-brand-default:focus-visible:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  --tw-ring-offset-color: var(--color-ink-brand-default);
}

.disabled\:tw-cursor-not-allowed:disabled:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  cursor: not-allowed;
}

.disabled\:tw-border-button-disabled-bg:disabled:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  border-color: var(--color-button-disabled-bg);
}

.disabled\:tw-bg-button-disabled-bg:disabled:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  background-color: var(--color-button-disabled-bg);
}

.disabled\:tw-text-ink-disabled:disabled:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  color: var(--color-ink-disabled);
}

.disabled\:tw-no-underline:disabled:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  text-decoration-line: none;
}

.tw-group:hover:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) .group-hover\:tw-visible {
  visibility: visible;
}

.tw-group:hover:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) .group-hover\:tw-scale-105 {
  --tw-scale-x: 1.05;
  --tw-scale-y: 1.05;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.tw-group:hover .group-hover\:\!tw-border-base-400 {
  border-color: var(--color-base-400) !important;
}

.tw-group:hover .group-hover\:\!tw-border-ink-error {
  border-color: var(--color-ink-error) !important;
}

.tw-group:hover:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) .group-hover\:tw-border-surface-action {
  border-color: var(--color-surface-action);
}

.tw-group:hover:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) .group-hover\:tw-border-surface-default {
  border-color: var(--color-surface-default);
}

.tw-group:hover:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) .group-hover\:tw-bg-base-50 {
  background-color: var(--color-base-50);
}

.tw-group:hover:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) .group-hover\:tw-bg-button-tertiary-bg-hover {
  background-color: var(--color-button-tertiary-bg-hover);
}

.tw-group:hover:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) .group-hover\:tw-bg-surface-default {
  background-color: var(--color-surface-default);
}

.tw-group:hover:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) .group-hover\:tw-bg-surface-default-hover {
  background-color: var(--color-surface-default-hover);
}

.tw-group:hover:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) .group-hover\:tw-p-0 {
  padding: 0;
}

.tw-group:hover:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) .group-hover\:tw-underline {
  text-decoration-line: underline;
}

.tw-group:hover:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) .group-hover\:tw-transition-\[padding\] {
  transition-property: padding;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.tw-group:hover:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) .group-hover\:tw-duration-150 {
  transition-duration: .15s;
}

.tw-group:hover:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) .group-hover\:checked\:tw-bg-surface-action:checked {
  background-color: var(--color-surface-action);
}

.tw-group:focus:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) .group-focus\:tw-visible {
  visibility: visible;
}

.tw-peer:checked:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) ~ .peer-checked\:tw-block {
  display: block;
}

.tw-peer:checked:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) ~ .peer-checked\:tw-fill-ink-brand-default {
  fill: var(--color-ink-brand-default);
}

.tw-peer:checked:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) ~ .peer-checked\:tw-fill-ink-brand-inverted {
  fill: var(--color-ink-brand-inverted);
}

.data-\[disabled\]\:tw-cursor-not-allowed[data-disabled]:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  cursor: not-allowed;
}

.data-\[invalid\]\:data-\[hover\]\:tw-border-error-600[data-hover][data-invalid]:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#), .data-\[invalid\]\:tw-border-error-600[data-invalid]:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  border-color: var(--color-error-600);
}

.tw-group[data-checked]:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) .group-data-\[checked\]\:tw-translate-x-6 {
  --tw-translate-x: 1.5rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.tw-group[data-disabled]:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) .group-data-\[disabled\]\:tw-bg-base-300 {
  background-color: var(--color-base-300);
}

@container (width >= 24rem) {
  .\@sm\:tw-line-clamp-2:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    overflow: hidden;
  }

  .\@sm\:tw-aspect-video:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
    aspect-ratio: 16 / 9;
  }

  .\@sm\:tw-w-full:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
    width: 100%;
  }

  .\@sm\:tw-flex-col:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
    flex-direction: column;
  }

  .\@sm\:tw-px-4:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .\@sm\:tw-py-5:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
  }

  .\@sm\:tw-pb-5:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
    padding-bottom: 1.25rem;
  }

  .\@sm\:tw-pt-4:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
    padding-top: 1rem;
  }

  .\@sm\:tw-text-2xl:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
    font-size: 1.5rem;
    line-height: 2rem;
  }

  .\@sm\:tw-text-lg:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
    font-size: 1.125rem;
    line-height: 1.75rem;
  }
}

@container (width >= 28rem) {
  .\@md\:tw-flex-col-reverse:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
    flex-direction: column-reverse;
  }

  .\@md\:tw-gap-6:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
    gap: 1.5rem;
  }

  .\@md\:tw-text-lg:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
    font-size: 1.125rem;
    line-height: 1.75rem;
  }
}

@container (width >= 32rem) {
  .\@lg\:tw-gap-6:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
    gap: 1.5rem;
  }

  .\@lg\:tw-text-3xl:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
    font-size: 1.875rem;
    line-height: 2.25rem;
  }
}

@container (width >= 36rem) {
  .\@xl\:tw-order-1:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
    order: 1;
  }

  .\@xl\:tw-col-span-6:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
    grid-column: span 6 / span 6;
  }

  .\@xl\:tw-line-clamp-3:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    overflow: hidden;
  }

  .\@xl\:tw-line-clamp-4:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    overflow: hidden;
  }

  .\@xl\:tw-block:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
    display: block;
  }

  .\@xl\:tw-items-start:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
    align-items: flex-start;
  }

  .\@xl\:tw-justify-center:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
    justify-content: center;
  }

  .\@xl\:tw-p-10:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
    padding: 2.5rem;
  }
}

@container (width >= 42rem) {
  .\@2xl\:tw-p-10:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
    padding: 2.5rem;
  }
}

@container (width >= 360px) {
  .\@\[360px\]\:tw-pb-5:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
    padding-bottom: 1.25rem;
  }

  .\@\[360px\]\:tw-text-xl:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }
}

@container (width >= 500px) {
  .\@\[500px\]\:tw-block:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
    display: block;
  }

  .\@\[500px\]\:tw-inline:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
    display: inline;
  }

  .\@\[500px\]\:tw-pb-6:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
    padding-bottom: 1.5rem;
  }

  .\@\[500px\]\:tw-text-2xl:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
    font-size: 1.5rem;
    line-height: 2rem;
  }
}

@container (width >= 575px) {
  .\@\[575px\]\:tw-block:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
    display: block;
  }

  .\@\[575px\]\:tw-hidden:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
    display: none;
  }

  .\@\[575px\]\:tw-flex-row:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
    flex-direction: row;
  }

  .\@\[575px\]\:tw-items-start:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
    align-items: flex-start;
  }

  .\@\[575px\]\:tw-items-end:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
    align-items: flex-end;
  }

  .\@\[575px\]\:tw-items-center:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
    align-items: center;
  }

  .\@\[575px\]\:tw-items-baseline:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
    align-items: baseline;
  }

  .\@\[575px\]\:tw-items-stretch:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
    align-items: stretch;
  }

  .\@\[575px\]\:tw-px-2:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
    padding-left: .5rem;
    padding-right: .5rem;
  }

  .\@\[575px\]\:tw-pl-1:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
    padding-left: .25rem;
  }

  .\@\[575px\]\:tw-pr-1:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
    padding-right: .25rem;
  }

  .\@\[575px\]\:tw-text-right:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
    text-align: right;
  }
}

@container (width >= 700px) {
  .\@\[700px\]\:tw-w-full:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
    width: 100%;
  }

  .\@\[700px\]\:tw-px-16:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
    padding-left: 4rem;
    padding-right: 4rem;
  }

  .\@\[700px\]\:tw-pb-10:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
    padding-bottom: 2.5rem;
  }

  .\@\[700px\]\:tw-text-center:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
    text-align: center;
  }

  .\@\[700px\]\:tw-text-3xl:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
    font-size: 1.875rem;
    line-height: 2.25rem;
  }
}

@media not all and (width >= 1024px) {
  .max-lg\:tw-absolute:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
    position: absolute;
  }

  .max-lg\:tw-m-\[-1px\]:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
    margin: -1px;
  }

  .max-lg\:tw-grid:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
    display: grid;
  }

  .max-lg\:tw-hidden:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
    display: none;
  }

  .max-lg\:tw-h-\[1px\]:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
    height: 1px;
  }

  .max-lg\:tw-w-\[1px\]:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
    width: 1px;
  }

  .max-lg\:tw-w-full:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
    width: 100%;
  }

  .max-lg\:tw-grid-cols-1:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }

  .max-lg\:tw-grid-cols-2:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .max-lg\:tw-justify-items-end:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
    justify-items: end;
  }

  .max-lg\:tw-overflow-hidden:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
    overflow: hidden;
  }

  .max-lg\:tw-text-clip:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
    text-overflow: clip;
  }

  .max-lg\:tw-border:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
    border-width: 1px;
  }

  .max-lg\:tw-border-transparent:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
    border-color: #0000;
  }

  .max-lg\:tw-border-b-ink-border-subtle:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
    border-bottom-color: var(--color-ink-border-subtle);
  }

  .max-lg\:tw-font-medium:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
    font-weight: 500;
  }

  .max-lg\:before\:tw-mr-1:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):before {
    content: var(--tw-content);
    margin-right: .25rem;
  }

  .max-lg\:before\:tw-hyphens-auto:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):before {
    content: var(--tw-content);
    -webkit-hyphens: auto;
    hyphens: auto;
  }

  .max-lg\:before\:tw-font-normal:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):before {
    content: var(--tw-content);
    font-weight: 400;
  }

  .max-lg\:before\:tw-text-ink-brand-default:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):before {
    content: var(--tw-content);
    color: var(--color-ink-brand-default);
  }

  .max-lg\:before\:tw-content-\[attr\(headers\)\]:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):before {
    --tw-content: attr(headers);
    content: var(--tw-content);
  }

  .max-lg\:before\:tw-content-none:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):before {
    --tw-content: none;
    content: var(--tw-content);
  }

  .max-lg\:last\:tw-border-b-0:last-child:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
    border-bottom-width: 0;
  }

  .even\:max-lg\:tw-border-t:nth-child(2n):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
    border-top-width: 1px;
  }

  .even\:max-lg\:tw-border-t-ink-border-subtle:nth-child(2n):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
    border-top-color: var(--color-ink-border-subtle);
  }
}

@media (width >= 240px) {
  .min-\[240px\]\:tw-max-w-40:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
    max-width: 10rem;
  }

  .min-\[240px\]\:tw-basis-\[156px\]:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
    flex-basis: 156px;
  }
}

@media (width >= 375px) {
  .min-\[375px\]\:tw-max-w-44:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
    max-width: 11rem;
  }

  .min-\[375px\]\:tw-basis-\[171px\]:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
    flex-basis: 171px;
  }
}

@media (width >= 424px) {
  .min-\[424px\]\:tw-max-w-\[49\%\]:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
    max-width: 49%;
  }

  .min-\[424px\]\:tw-basis-\[49\%\]:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
    flex-basis: 49%;
  }
}

@media (width >= 585px) {
  .min-\[585px\]\:tw-max-w-\[32\%\]:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
    max-width: 32%;
  }

  .min-\[585px\]\:tw-basis-\[32\%\]:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
    flex-basis: 32%;
  }
}

@media (width >= 640px) {
  .sm\:tw-my-1:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
    margin-top: .25rem;
    margin-bottom: .25rem;
  }

  .sm\:tw-mb-6:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
    margin-bottom: 1.5rem;
  }

  .sm\:tw-mt-0:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
    margin-top: 0;
  }

  .sm\:tw-block:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
    display: block;
  }

  .sm\:tw-flex:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
    display: flex;
  }

  .sm\:tw-hidden:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
    display: none;
  }

  .sm\:tw-w-1\/2:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
    width: 50%;
  }

  .sm\:tw-w-1\/3:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
    width: 33.3333%;
  }

  .sm\:tw-w-1\/4:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
    width: 25%;
  }

  .sm\:tw-w-2\/3:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
    width: 66.6667%;
  }

  .sm\:tw-w-56:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
    width: 14rem;
  }

  .sm\:tw-w-\[1000px\]:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
    width: 1000px;
  }

  .sm\:tw-w-\[400px\]:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
    width: 400px;
  }

  .sm\:tw-w-\[600px\]:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
    width: 600px;
  }

  .sm\:tw-w-\[800px\]:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
    width: 800px;
  }

  .sm\:tw-basis-1\/2:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
    flex-basis: 50%;
  }

  .sm\:tw-basis-1\/4:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
    flex-basis: 25%;
  }

  .sm\:tw-grid-cols-2:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .sm\:tw-flex-row:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
    flex-direction: row;
  }

  .sm\:tw-flex-nowrap:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
    flex-wrap: nowrap;
  }

  .sm\:tw-items-start:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
    align-items: flex-start;
  }

  .sm\:tw-items-center:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
    align-items: center;
  }

  .sm\:tw-justify-start:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
    justify-content: flex-start;
  }

  .sm\:tw-gap-0:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
    gap: 0;
  }

  .sm\:tw-gap-4:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
    gap: 1rem;
  }

  .sm\:tw-gap-6:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
    gap: 1.5rem;
  }

  .sm\:tw-px-4:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .sm\:tw-py-1:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
    padding-top: .25rem;
    padding-bottom: .25rem;
  }

  .sm\:tw-pb-8:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
    padding-bottom: 2rem;
  }

  .sm\:tw-pt-0:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
    padding-top: 0;
  }

  .sm\:tw-text-2xl:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
    font-size: 1.5rem;
    line-height: 2rem;
  }
}

@media (width >= 768px) {
  .md\:tw-mx-auto:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
    margin-left: auto;
    margin-right: auto;
  }

  .md\:tw-mr-3:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
    margin-right: .75rem;
  }

  .md\:tw-block:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
    display: block;
  }

  .md\:tw-inline-block:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
    display: inline-block;
  }

  .md\:tw-flex:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
    display: flex;
  }

  .md\:tw-hidden:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
    display: none;
  }

  .md\:tw-aspect-\[16\/10\]:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
    aspect-ratio: 16 / 10;
  }

  .md\:tw-w-1\/2:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
    width: 50%;
  }

  .md\:tw-w-2\/3:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
    width: 66.6667%;
  }

  .md\:tw-w-3\/4:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
    width: 75%;
  }

  .md\:tw-w-44:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
    width: 11rem;
  }

  .md\:tw-w-7\/12:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
    width: 58.3333%;
  }

  .md\:tw-w-\[1000px\]:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
    width: 1000px;
  }

  .md\:tw-w-\[1200px\]:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
    width: 1200px;
  }

  .md\:tw-w-\[800px\]:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
    width: 800px;
  }

  .md\:tw-w-full:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
    width: 100%;
  }

  .md\:tw-max-w-3xl:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
    max-width: 48rem;
  }

  .md\:tw-max-w-4xl:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
    max-width: 56rem;
  }

  .md\:tw-max-w-\[24\%\]:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
    max-width: 24%;
  }

  .md\:tw-max-w-lg:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
    max-width: 32rem;
  }

  .md\:tw-max-w-md:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
    max-width: 28rem;
  }

  .md\:tw-max-w-xl:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
    max-width: 36rem;
  }

  .md\:tw-flex-1:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
    flex: 1;
  }

  .md\:tw-grow-0:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
    flex-grow: 0;
  }

  .md\:tw-basis-\[24\%\]:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
    flex-basis: 24%;
  }

  .md\:tw-snap-x:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
    scroll-snap-type: x var(--tw-scroll-snap-strictness);
  }

  .md\:tw-snap-mandatory:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
    --tw-scroll-snap-strictness: mandatory;
  }

  .md\:tw-snap-center:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
    scroll-snap-align: center;
  }

  .md\:tw-flex-row:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
    flex-direction: row;
  }

  .md\:tw-justify-start:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
    justify-content: flex-start;
  }

  .md\:tw-justify-center:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
    justify-content: center;
  }

  .md\:tw-justify-between:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
    justify-content: space-between;
  }

  .md\:tw-text-nowrap:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
    text-wrap: nowrap;
  }

  .md\:tw-px-0:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
    padding-left: 0;
    padding-right: 0;
  }

  .md\:tw-px-12:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
    padding-left: 3rem;
    padding-right: 3rem;
  }

  .md\:tw-px-3:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
    padding-left: .75rem;
    padding-right: .75rem;
  }

  .md\:tw-text-end:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
    text-align: end;
  }

  .tw-group:hover:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) .md\:group-hover\:tw-translate-x-0 {
    --tw-translate-x: 0px;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .tw-group:hover:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) .md\:group-hover\:tw-translate-x-\[20px\] {
    --tw-translate-x: 20px;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }
}

@media (width >= 1024px) {
  .lg\:tw-absolute:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
    position: absolute;
  }

  .lg\:tw-relative:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
    position: relative;
  }

  .lg\:tw-bottom-0:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
    bottom: 0;
  }

  .lg\:tw-left-0:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
    left: 0;
  }

  .lg\:tw-left-auto:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
    left: auto;
  }

  .lg\:tw-right-0:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
    right: 0;
  }

  .lg\:tw-mx-0:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
    margin-left: 0;
    margin-right: 0;
  }

  .lg\:tw-mx-auto:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
    margin-left: auto;
    margin-right: auto;
  }

  .lg\:tw-mb-0:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
    margin-bottom: 0;
  }

  .lg\:tw-block:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
    display: block;
  }

  .lg\:tw-flex:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
    display: flex;
  }

  .lg\:tw-hidden:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
    display: none;
  }

  .lg\:tw-size-10:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
    width: 2.5rem;
    height: 2.5rem;
  }

  .lg\:tw-size-12:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
    width: 3rem;
    height: 3rem;
  }

  .lg\:tw-size-14:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
    width: 3.5rem;
    height: 3.5rem;
  }

  .lg\:tw-size-16:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
    width: 4rem;
    height: 4rem;
  }

  .lg\:tw-w-1\/2:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
    width: 50%;
  }

  .lg\:tw-w-1\/3:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
    width: 33.3333%;
  }

  .lg\:tw-w-2\/3:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
    width: 66.6667%;
  }

  .lg\:tw-w-20:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
    width: 5rem;
  }

  .lg\:tw-w-24:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
    width: 6rem;
  }

  .lg\:tw-w-3\/4:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
    width: 75%;
  }

  .lg\:tw-w-40:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
    width: 10rem;
  }

  .lg\:tw-w-auto:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
    width: auto;
  }

  .lg\:tw-w-full:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
    width: 100%;
  }

  .lg\:tw-min-w-20:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
    min-width: 5rem;
  }

  .lg\:tw-min-w-40:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
    min-width: 10rem;
  }

  .lg\:tw-min-w-\[--search-input-min-width\]:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
    min-width: var(--search-input-min-width);
  }

  .lg\:tw-max-w-\[19\.2\%\]:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
    max-width: 19.2%;
  }

  .lg\:tw-basis-\[19\.2\%\]:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
    flex-basis: 19.2%;
  }

  .lg\:tw-grid-cols-3:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .lg\:tw-flex-row:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
    flex-direction: row;
  }

  .lg\:tw-flex-row-reverse:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
    flex-direction: row-reverse;
  }

  .lg\:tw-flex-nowrap:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
    flex-wrap: nowrap;
  }

  .lg\:tw-items-start:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
    align-items: flex-start;
  }

  .lg\:tw-items-end:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
    align-items: flex-end;
  }

  .lg\:tw-items-center:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
    align-items: center;
  }

  .lg\:tw-items-baseline:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
    align-items: baseline;
  }

  .lg\:tw-items-stretch:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
    align-items: stretch;
  }

  .lg\:tw-justify-start:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
    justify-content: flex-start;
  }

  .lg\:tw-justify-between:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
    justify-content: space-between;
  }

  .lg\:tw-gap-4:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
    gap: 1rem;
  }

  .lg\:tw-gap-8:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
    gap: 2rem;
  }

  .lg\:tw-gap-x-8:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
    column-gap: 2rem;
  }

  .lg\:tw-rounded-t-lg:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
    border-top-left-radius: .5rem;
    border-top-right-radius: .5rem;
  }

  .lg\:tw-border-l:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
    border-left-width: 1px;
  }

  .lg\:tw-border-solid:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
    border-style: solid;
  }

  .lg\:tw-border-ink-border-subtle:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
    border-color: var(--color-ink-border-subtle);
  }

  .lg\:tw-p-12:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
    padding: 3rem;
  }

  .lg\:tw-p-4:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
    padding: 1rem;
  }

  .lg\:tw-px-0:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
    padding-left: 0;
    padding-right: 0;
  }

  .lg\:tw-px-10:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }

  .lg\:tw-px-4:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .lg\:tw-px-6:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .lg\:tw-px-\[--article-px\]:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
    padding-left: var(--article-px);
    padding-right: var(--article-px);
  }

  .lg\:tw-py-10:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }

  .lg\:tw-py-12:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }

  .lg\:tw-py-8:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  .lg\:tw-pb-0:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
    padding-bottom: 0;
  }

  .lg\:tw-pb-12:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
    padding-bottom: 3rem;
  }

  .lg\:tw-pl-1:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
    padding-left: .25rem;
  }

  .lg\:tw-pr-1:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
    padding-right: .25rem;
  }

  .lg\:tw-pr-4:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
    padding-right: 1rem;
  }

  .lg\:tw-pr-6:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
    padding-right: 1.5rem;
  }

  .lg\:tw-pt-1:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
    padding-top: .25rem;
  }

  .lg\:tw-pt-12:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
    padding-top: 3rem;
  }

  .lg\:tw-pt-2:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
    padding-top: .5rem;
  }

  .lg\:tw-text-center:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
    text-align: center;
  }

  .lg\:tw-text-right:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
    text-align: right;
  }

  .lg\:tw-text-end:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
    text-align: end;
  }

  .lg\:tw-text-2xl:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
    font-size: 1.5rem;
    line-height: 2rem;
  }

  .lg\:tw-text-base:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
    font-size: 1rem;
    line-height: 1.5rem;
  }

  .lg\:tw-text-lg:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
    font-size: 1.125rem;
    line-height: 1.75rem;
  }

  .lg\:tw-text-xl:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }

  .lg\:tw-font-normal:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
    font-weight: 400;
  }
}

@media (width >= 1280px) {
  .xl\:tw-max-w-\[16\%\]:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
    max-width: 16%;
  }

  .xl\:tw-basis-\[16\%\]:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
    flex-basis: 16%;
  }

  .xl\:tw-p-40:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
    padding: 10rem;
  }

  .xl\:tw-px-10:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }

  .xl\:tw-px-12:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
    padding-left: 3rem;
    padding-right: 3rem;
  }
}

@media print {
  .print\:tw-col-span-12:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
    grid-column: span 12 / span 12;
  }

  .print\:tw-col-span-3:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
    grid-column: span 3 / span 3;
  }

  .print\:tw-col-span-4:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
    grid-column: span 4 / span 4;
  }

  .print\:tw-col-span-6:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
    grid-column: span 6 / span 6;
  }

  .print\:tw-col-span-8:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
    grid-column: span 8 / span 8;
  }

  .print\:tw-col-span-9:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
    grid-column: span 9 / span 9;
  }

  .print\:tw-mx-auto:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
    margin-left: auto;
    margin-right: auto;
  }

  .print\:tw-mb-1:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
    margin-bottom: .25rem;
  }

  .print\:tw-mb-4:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
    margin-bottom: 1rem;
  }

  .print\:tw-mb-6:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
    margin-bottom: 1.5rem;
  }

  .print\:tw-block:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
    display: block;
  }

  .print\:tw-grid:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
    display: grid;
  }

  .print\:tw-hidden:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
    display: none;
  }

  .print\:tw-w-full:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
    width: 100%;
  }

  .print\:tw-grid-cols-12:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
    grid-template-columns: repeat(12, minmax(0, 1fr));
  }

  .print\:tw-items-center:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
    align-items: center;
  }

  .print\:tw-gap-6:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
    gap: 1.5rem;
  }

  .print\:tw-pt-16:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
    padding-top: 4rem;
  }

  .print\:tw-text-\[80\%\]:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
    font-size: 80%;
  }
}

.\[\&\:disabled\]\:tw-bg-surface-default:disabled:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  background-color: var(--color-surface-default);
}

.\[\&\:has\(\:focus-visible\)\]\:tw-ring:has(:focus-visible):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.\[\&\:has\(\:focus-visible\)\]\:tw-ring-2:has(:focus-visible):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.\[\&\>\*\:nth-last-child\(2\)\]\:tw-inline-block:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) > :nth-last-child(2) {
  display: inline-block;
}

.\[\&\>\*\]\:tw-hidden:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) > * {
  display: none;
}

.\[\&\>\*\]\:tw-grow:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) > * {
  flex-grow: 1;
}

.\[\&\>\*\]\:tw-text-headerFooterText:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) > * {
  color: var(--color-header-footer-text);
}

@media (width >= 640px) {
  .\[\&\>\*\]\:sm\:tw-w-1\/2:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) > * {
    width: 50%;
  }
}

@media (width >= 768px) {
  .md\:\[\&\>\*\]\:tw-flex:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) > * {
    display: flex;
  }

  .\[\&\>\*\]\:md\:tw-w-1\/2:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) > * {
    width: 50%;
  }

  .\[\&\>\*\]\:md\:tw-w-1\/3:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) > * {
    width: 33.3333%;
  }

  .\[\&\>\*\]\:md\:tw-w-1\/4:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) > * {
    width: 25%;
  }
}

.\[\&\>\.drawingBuffer\]\:tw-absolute:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) > .drawingBuffer {
  position: absolute;
}

.\[\&\>\.drawingBuffer\]\:tw-inset-0:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) > .drawingBuffer {
  inset: 0;
}

.\[\&\>button\>svg\]\:hover\:tw-text-\[--dsp-color-ink-favorites\]:hover:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) > button > svg {
  color: var(--dsp-color-ink-favorites);
}

.\[\&\>button\]\:tw-h-\[43px\]:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) > button {
  height: 43px;
}

.\[\&\>button\]\:tw-w-\[43px\]:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) > button {
  width: 43px;
}

.\[\&\>button\]\:tw-flex-grow-0:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) > button {
  flex-grow: 0;
}

.disabled\:\[\&\>button\]\:tw-bg-transparent:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) > button:disabled {
  background-color: #0000;
}

.\[\&\>canvas\]\:tw-w-full:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) > canvas {
  width: 100%;
}

.\[\&\>div\>div\>div\>div\>p\]\:tw-mb-0:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) > div > div > div > div > p {
  margin-bottom: 0;
}

.\[\&\>div\]\:tw-h-\[43px\]:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) > div {
  height: 43px;
}

.\[\&\>div\]\:tw-h-full:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) > div {
  height: 100%;
}

.\[\&\>div\]\:tw-min-w-\[30px\]:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) > div {
  min-width: 30px;
}

.\[\&\>div\]\:tw-max-w-\[--main-column-max-width\]:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) > div {
  max-width: var(--main-column-max-width);
}

.\[\&\>div\]\:tw-max-w-\[120px\]:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) > div {
  max-width: 120px;
}

.\[\&\>div\]\:tw-flex-grow:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) > div {
  flex-grow: 1;
}

.\[\&\>div\]\:tw-overflow-hidden:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) > div {
  overflow: hidden;
}

.\[\&\>div\]\:tw-overflow-visible:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) > div {
  overflow: visible;
}

.\[\&\>div\]\:tw-px-3:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) > div {
  padding-left: .75rem;
  padding-right: .75rem;
}

@media (width >= 640px) {
  .sm\:\[\&\>div\]\:tw-px-4:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) > div {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

@media (width >= 768px) {
  .md\:\[\&\>div\]\:tw-px-12:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) > div {
    padding-left: 3rem;
    padding-right: 3rem;
  }
}

.\[\&\>img\:first-child\]\:hover\:tw-hover-calendar-slot:hover:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) > img:first-child {
  transform: perspective(1200px)rotateY(-50deg);
}

.\[\&\>li\:nth-last-child\(2\)\>div\>svg\]\:tw-rotate-180:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) > li:nth-last-child(2) > div > svg {
  --tw-rotate: 180deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

@media (width >= 768px) {
  .md\:\[\&\>li\:nth-last-child\(2\)\>div\>svg\]\:tw-rotate-0:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) > li:nth-last-child(2) > div > svg {
    --tw-rotate: 0deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }
}

.\[\&\>li\]\:tw-list-decimal:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) > li {
  list-style-type: decimal;
}

.\[\&\>li\]\:tw-list-disc:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) > li {
  list-style-type: disc;
}

.\[\&\>svg\]\:-tw-m-0\.5:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) > svg {
  margin: -.125rem;
}

.\[\&\>svg\]\:-tw-m-1:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) > svg {
  margin: -.25rem;
}

.\[\&\>svg\]\:tw-inline:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) > svg {
  display: inline;
}

.\[\&\>svg\]\:tw-size-5:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) > svg {
  width: 1.25rem;
  height: 1.25rem;
}

.\[\&\>svg\]\:tw-h-\[20px\]:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) > svg {
  height: 20px;
}

.\[\&\>svg\]\:tw-w-\[20px\]:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) > svg {
  width: 20px;
}

.\[\&\>svg\]\:tw-flex-shrink-0:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) > svg {
  flex-shrink: 0;
}

.\[\&\>svg\]\:tw-rotate-90:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) > svg {
  --tw-rotate: 90deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.\[\&\>svg\]\:\!tw-fill-base-0 > svg {
  fill: var(--color-base-0) !important;
}

.\[\&\>svg\]\:\!tw-fill-ink-default > svg {
  fill: var(--color-ink-default) !important;
}

.\[\&\>svg\]\:tw-ring:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) > svg {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.\[\&\>svg\]\:disabled\:tw-fill-ink-disabled:disabled:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) > svg {
  fill: var(--color-ink-disabled);
}

.\[\&\>table\]\:tw-table-auto:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) > table {
  table-layout: auto;
}

.\[\&\>thead\]\:tw-sticky:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) > thead {
  position: sticky;
}

.\[\&\>thead\]\:tw-top-0:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) > thead {
  top: 0;
}

.\[\&\>thead\]\:tw-z-10:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) > thead {
  z-index: 10;
}

.\[\&\>thead\]\:tw-border-b-ink-border-subtle:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) > thead {
  border-bottom-color: var(--color-ink-border-subtle);
}

.\[\&\>video\]\:tw-w-full:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) > video {
  width: 100%;
}

.\[\&_input\]\:tw-h-8:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) input {
  height: 2rem;
}

.\[\&_input\]\:tw-h-full:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) input {
  height: 100%;
}

.\[\&_input\]\:tw-rounded-none:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) input {
  border-radius: 0;
}

.\[\&_input\]\:tw-border-0:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) input {
  border-width: 0;
}

.\[\&_input\]\:tw-px-0:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) input {
  padding-left: 0;
  padding-right: 0;
}

.\[\&_input\]\:tw-text-center:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) input {
  text-align: center;
}

.\[\&_input\]\:tw-text-sm:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) input {
  font-size: .875rem;
  line-height: 1.25rem;
}

.\[\&_input\]\:tw-text-xl:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) input {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.\[\&_input\]\:tw-font-bold:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) input {
  font-weight: 700;
}

.\[\&_input\]\:focus\:tw-border-0:focus:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) input, .\[\&_input\]\:active\:tw-border-0:active:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) input {
  border-width: 0;
}

.\[\&_label\]\:tw-text-base-0:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) label {
  color: var(--color-base-0);
}

.\[\&_span\]\:tw-font-semibold:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) span, .\[\&_strong\]\:tw-font-semibold:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) strong {
  font-weight: 600;
}

@media not all and (width >= 1024px) {
  .max-lg\:first-of-type\:\[\&_td\]\:tw-border-\[1\.5px\]:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) td:first-of-type {
    border-width: 1.5px;
  }

  .max-lg\:first-of-type\:\[\&_td\]\:tw-bg-surface-default-hover:not(#\#):not(#\#):not(#\#):not(#\#):not(#\#):not(#\#) td:first-of-type {
    background-color: var(--color-surface-default-hover);
  }
}
/*# sourceMappingURL=index.fe26e72b.css.map */
